header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background: white;
    width: 100%;
    z-index: 10;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .06);

    .logo {
        align-self: center;
        margin: 0 10px 0 50px;
        width: 0;
        display: flex;

        a {
            height: 65px;

            img {
                max-height: 65px;
            }
        }
    }

    .nav {
        align-self: center;
        margin: 0 50px 0 10px;
    }

    .logo, .nav {
        @media (max-width: 769px) {
            margin: 0 10px;
        }
    }

    .nav {
        display: flex;
        align-items: center;

        i.fas {
            font-size: 20px;
            padding: 28px 5px;
        }

        i.fas, span {
            @media (max-width: 425px) {
                display: none;
            }
        }

        span {
            margin: 0 10px;
            font-size: 15px;
            font-weight: 500;
            text-transform: uppercase;
        }
        form {
            display: flex;
            justify-content: flex-end;
            position: relative;
            left: 35px;

            .searchBar {
                background: #fff;
                z-index: 1000;
                font-size: 18px;
                padding: 0;
                border: none;
                border-bottom: 1.5px solid #fff;
                transition: .3s;
                width: 0;
                padding: 0;
                height: 40px;
            }
            
            .showSearchBar {
                width: 100%;
                border-color: #000;
            }
        }

        .icon-search {
            width: 22px;
            height: 22px;
            margin: 0 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/images/loop.png");
        }
        
        .icon-burger-close {
            width: 46px;
            height: 26px;
            margin: 0 35px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/images/burger-black.svg");
            
            @media (max-width: 476px) {
                margin: 0 10px;
            }
        }
    }

    .nav-right {
        display: flex;
        width: 620px;
        height: 100%;
        position: fixed;
        top: 0;
        right: -620px;
        overflow-x: hidden;
        transition: .4s;
        z-index: 99999;
        background-color: #000;


        @media (max-width: 769px) {
            width: 620px;
            right: -620px;
        }

        .icon-burger-open {
            display: flex;
            width: 31px;
            height: 31px;
            position: absolute;
            right: 87px;
            top: 30px;
            cursor: pointer;
            background-image: url("/images/svg/burger_menu_close.svg");
            color: #fff;
            font-size: 50px;

            @media (max-width: 769px) {
                right: 30px;
            }
        }

        ul {
            list-style-type: none;
            vertical-align: middle;
            display: inline-block;
            margin: auto 0;
            width: 100%;
            
            li {
                padding: 0 0 40px 60px;

                @media (max-width: 769px) {
                    padding-left: 20px;
                }
                a {
                    padding: 0;
                    text-decoration: none;
                    font-size: 35px;
                    display: block;
                    transition: .3s;
                    text-transform: lowercase;
                    font-family: 'Poppins', sans-serif;
                    color: #818181 !important;
                    font-weight: 600;
                    transition: .3s;
                    cursor: pointer;

                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }
    }

    .translationSelection {
        display: none;
        width: 100px;
        height: 80px;
        background: #fff;
        position: absolute;
        right: 10px;
        top: 60px;

        ul {
            margin: 0 auto;
            padding: 0;
            display: flex;
            align-self: center;
            flex-direction: column;
            list-style: none;
            text-transform: uppercase;
            font-size: 16px;

            li {
                align-self: center;

                a {
                    text-decoration: none;
                    color: #000;
                }
            } 
        }
    }

    .showTranslation {
        display: flex;
        z-index: 1;
    }
}

//SEARCH RESULT
.searchContainer {
    display: none;
    padding-top: 115px;
    flex-direction: column; 
    margin: 0 7.5%;

    .titleResult, .countSearch{
        font-weight: 700;
        margin-bottom: 25px;
        font-size: 27px;
    }

    .searchResultContainer {
        display: flex;
        flex-wrap: wrap;

        a {
            flex: 0 0 20%;
            text-decoration: none;
            color: #000;

            @media (max-width: 1441px) {
                flex: 0 0 25%;
            }

            @media (max-width: 1025px) {
                flex: 0 0 33%;
            }

            @media (max-width: 769px) {
                flex: 0 0 50%;
            }

            @media (max-width: 545px) {
                flex: 0 0 100%;
            }

            .containerOneResult {
                margin: 0 15px 48px 15px;

                .cover {
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    min-height: 280px;
                    max-width: 220px;
                    transition: .3s;

                    &:hover {
                        transform: scale(1.05);
                        box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, .2);
                    }

                    @media (max-width: 545px) {
                        margin: auto;
                    }
                }

                div {
                    @media (max-width: 545px) {
                        text-align: center;
                    }

                    p {
                        text-transform: capitalize;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
