.teamPage {

    h2 {
        font-size: 40px;
        font-weight: 800;
        margin: 15px 20px 20px 20px;

        @media (max-width: 769px) {
            font-size: 35px;
        }
    }

    .containerImg {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 55px;

        .menuTeam {
            margin: 0;
            color: #fff;
            position: fixed;
            background: #000;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 55px;
            z-index: 1;

            @media (max-width: 820px) {
                display: none;
            }

            span:first-child {
                color: #ff0000;
            }

            span {
                margin: 0 3%;
                font-size: 18px;
                font-weight: 900;

                &:hover {
                    color: #ff0000;
                }
            }
        }
        .menuTeamMobileContainer {
            display: flex;
            width: 100%;
            flex-direction: column;
            position: fixed;
            align-items: center;
            z-index: 1;

            @media (min-width: 820px) {
                display: none;
            }

            .inputMenuTeam {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                color: #fff;
                position: fixed;
                background: #000;
                width: 100%;
                text-align: center;
                height: 60px;

                p {
                    color: red;
                    margin: 10px;
                    font-size: 21px;
                    font-weight: 700;
                }

                i.fas {
                    margin: 0 5px;
                    color: #fff;
                    z-index: 11;
                }
            }

            .menuTeamMobile {
                background: #000;
                display: flex;
                display: none;
                width: 98%;
                flex-direction: column;
                align-items: center;
                color: #fff;
                position: absolute;
                top: 60px;
                padding: 10px;
                z-index: 10000;
    
                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 60px;
                    border-bottom: 1px solid #fff;
                    width: 100%;
                    cursor: pointer!important;
    
                    &:last-child {
                        border: none;
                    }
                }
            }

            .showMenuTeamMobile {
                display: flex;;
            }
        }

        img {
            margin-top: 60px;
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 660px;
            min-height: 380px;
            z-index: -1;
        }

        .containerContent {
            display: flex;
            flex-direction: column;
            position: absolute;
            color: #fff;
            left: 0;
            align-self: center;
            width: 60%;
            margin: 2% 5%;
            z-index: -1;
            
            h1 {
                font-size: 60px;
                line-height: 58px;

                @media (max-width: 820px) {
                    font-size: 35px;
                    line-height: 33px;
                }
            }

            p {
                font-size: 14px;
                margin: 10px 40px 0 0;

                @media (max-width: 820px) {
                    font-size: 12px;
                }
            }
        }
    }

    .containerCarouselSkew {
        display: flex;
        flex: 1;
        //align-items: center;
        margin: 5%;
        padding-top: 40px;

        @media (max-width: 820px) {
            flex-direction: column;
        }
        
        .containerImgSkew {
            display: flex;
            overflow: hidden;
            align-items: center;
            outline: none;
            flex: 1;

            .imgSkew {
                display: flex;
                background-image: url('/images/img-home.jpeg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%, 40% 650px);
                flex: 1;
                align-self: center;
                height: 290px;

                @media (max-width: 1025px) {
                    clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%, 30% 400px);
                }
                // @media (max-width: 520px) {
                //     margin-left: 20px;
                // }
            }
        }
        .carousel {
            display: flex;
            flex: 1;
            max-width: 65%;
            min-width: 60%;

            @media (max-width: 425px) {
                max-width: 100%;
            }

            .slick-list {
                width: 100%;
                flex:1;
            }
        }

        h2 {
            margin:15px 0 0 28px;
            line-height:34px;
        }

        p {
            flex: 1;
            margin: 0 30px 0 30px;

            @media (max-width: 820px) {
                margin-bottom: 20px;
                margin-top: 20px;
            }
        }
    }


    .showTeamSection {
        display: flex!important;
    }
    //CODIR
    .containerTeam {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 40px;

        .firstText {
            font-size: 14px;
            font-weight: 500;
            width: 40%;
            margin: 0 auto 35px auto;
            text-align: center;

            @media (max-width: 525px) {
                width: 80%;
            }
            
            p {
                margin-top: 0;
            }
        }

        .containerMember {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;

            @media (max-width: 769px) {
                flex-direction: column;
                margin-left: -40px;
            }
        }

        .containerImgSkew {
            display: flex;
            overflow: hidden;
            width: 400px;
            padding: 10px 50px;
            flex-direction: column;
            align-items: center;

            @media (max-width: 1400px) {
                width: 22.5%;
            }

            @media (max-width: 769px) {
                flex: inherit;
                padding: 0 10px;
                margin-left: 60px;
                width: 400px;
            }


            @media (max-width: 376px) {
                flex: inherit;
                padding: 0px;
                max-width: 320px;
            }
    
            .imgSkewParent {
                width: 215px;
                overflow: hidden;
                margin-bottom: 20px;
                transform: skew(20deg);

                .imgSkew {
                    transform: skew(-20deg);
                    display: flex;
                    background-image: url('/images/img-home.jpeg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top;
                    align-self: center;
                    width: 324px;
                    height: 285px;
                    left: -27%;
                    position: relative;
                }
    
            }
            p {
                text-align: center;
                margin: 0;
                margin-bottom: 20px;
                margin-left: 18%;
                font-size: 14px;
                color: #909090;
                font-weight: 500;

                @media (max-width: 525px) {
                    margin-left: 2%;
                    margin-right: 2%;
                }

                span {
                    font-weight: 900;
                    font-size: 18px;
                    color: #000;
                    font-weight: 700;
                }
            }
        }
    }

    .containerReward {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 40px;

        .firstText {
            font-size: 14px;
            font-weight: 500;
            width: 40%;
            margin: 0 auto 35px auto;
            text-align: center;

            @media (max-width: 525px) {
                width: 80%;
            }
            
            p {
                margin-top: 0;
            }
        }

        .containerAward {
            display: flex;
            margin: 30px 10%;
            min-width: 80%;
    
            @media (max-width: 769px) {
                flex-direction: column;
                align-items: center;
                margin: 20px;
            }
    
            img {
                height: 100px;
                width: 100px;
                align-self: center;
            }
    
            p:nth-child(1) {
                font-weight: 300;
                color: #909090;
                margin: 0;
            }
    
            p:nth-child(2) {
                font-weight: 700;
                margin: 0;
            }
    
            p:nth-child(3) {
                margin:10px 0 0 0;
            }
    
            .asideText {
                margin-left: 100px;
    
                @media (max-width: 769px) {
                    margin: 20px 10px 0 10px;
                }

                h3 {
                    margin-bottom: 0;
                    margin-bottom:15px;
                    font-weight:700;
                    font-size:16px;
                }

                p {
                    margin-bottom: 10px;
                    font-weight: 500;
                    color:#000;
                }

                //p:nth-child(1) {
                //    margin-bottom: 0;
                //    margin-bottom:15px;
                //    font-weight:500;
                //}

                //p:nth-child(2) {
                //    font-weight: 700;
                //}

                article {
                    font-size: 14px;
                    font-weight: 500;

                    p {
                        color:#000;
                    }
                }
            }
        }
    }

    .containerCoDir {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .firstText {
            font-size: 14px;
            font-weight: 500;
            width: 40%;
            margin: 0 auto 35px auto;
            text-align: center;

            @media (max-width: 525px) {
                width: 80%;
            }
            
            p {
                margin-top: 0;
            }
        }

        .containerMember {
            z-index: 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
            z-index: 0;

            @media (max-width: 769px) {
                flex-direction: column;
            }
        }

        .containerImgSkew {
            display: flex;
            width: 360px;
            padding: 10px 50px;
            z-index: -1;
            flex-direction: column;
            align-items: center;

            &:after {
                content: "";
                flex: auto;
            }

            @media (max-width: 1400px) {
                width: 22.5%;
            }


            @media (max-width: 769px) {
                width: 100%;
                flex: inherit;
                padding: 0 10px;
            }

            @media (max-width: 376px) {
                flex: inherit;
                padding: 0px;
                max-width: 320px;
            }

            .parentImgSkew {
                width: 215px;
                overflow: hidden;
                margin-bottom: 20px;
                transform: skew(20deg);
                transition: .3s;

                @media (max-width: 769px) {
                    width: 60%;
                }

                @media (max-width: 376px) {
                    width: 190px;
                }

                &:hover {
                    box-shadow: 3px 2px 8px 3px rgba(0, 0, 0, 0.4);

                    .imgSkew {
                        transform: scale(1.1) skew(-20deg);
                    }
                }
    
                .imgSkew {
                    display: flex;
                    
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top;
                    align-self: center;
                    left: -27%;
                    width: 324px;
                    height: 285px;
                    position: relative;
                    transform: skew(-20deg);
                    transition: .3s;

                    @media (max-width: 769px) {
                        width: 320px;
                    }

                    @media (max-width: 376px) {
                        width: 155%;
                    }
                }
            }

            p {
                text-align: center;
                margin: 0;
                margin-bottom: 20px;
                margin-left: 18%;
                font-size: 14px;
                color: #909090;
                font-weight: 500;

                span {
                    font-weight: 900;
                    font-size: 18px;
                    color: #000;
                    font-weight: 700;
                }
            }
        }
    }

    .containerCoDir .containerInterview {
        z-index: 1;
        display: flex;
        padding: 10px 0;
        background-color: #efefef;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        outline: none !important;

        .autoplayInterview {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .slick-list {
                width: 90%!important;
            }
        }

        .containerContInter {
            display: flex;
            margin: 0 120px;

            @media (max-width: 1025px) {
                flex-direction: column;
                margin: 0 20px;
            }

            .containerImgSkew2 {
                display: flex;
                overflow: hidden;
                width: 340px;
                padding: 10px;
                z-index: 0;
                flex-direction: column;
                align-items: center;
    
                @media (max-width: 1025px) {
                    width: 95%;
                    flex: inherit;
                }
        
                .imgSkew {
                    display: flex;
                    background-image: url('/images/img-home.jpeg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top;
                    clip-path: polygon(0px 30px, 270px 30px, 340px 260px, 100px 260px, 70px 260px);
                    align-self: center;
                    width: 340px;
                    height: 285px;
                }
    
                p {
                    text-align: center;
                    margin: 0;
                    margin-bottom: 20px;
    
                    span {
                        font-weight: 900;
                    }
                }
            }

            .containerQuestionAnswer {
                justify-content: center;
                display: flex;
                flex-direction: column;
                flex: 1;
                margin: 10px;
                margin-left: 30px;

                @media (max-width: 1025px) {
                    margin-left: 10px;
                    width: 95%;
                    flex: inherit;
                }

                article {
                    font-weight: 500;
                    margin-bottom: 0;

                    p {
                        margin: 0px;
                        padding: 0px;
                    }
                }
            }
        }

        h3 {
            text-align: left;
            font-size: 21px;
        }
    }
    .containerModal {
        top:20px;
    }

    .modal {
        .containerInterview {
            display: block !important;
        }

        p {
            overflow: initial;
        }
    }
}
