@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css");
.skipHeader {
  padding-top: 80px;
}

.imgCursor {
  display: none;
  transform: translate(-36.66%, -36.66%) !important;
  position: fixed !important;
  width: 30px;
  height: 30px;
  z-index: 10000000;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}

@media (max-width: 820px) {
  .imgCursor {
    display: none !important;
  }
}

* {
  cursor: url("/images/cursor.png"), auto !important;
}

@media (max-width: 820px) {
  * {
    cursor: inherit !important;
  }
}

* .isWhite {
  background: white !important;
}

* .isWhite h1, * .isWhite h2, * .isWhite article, * .isWhite article p, * .isWhite a, * .isWhite .slickPrev, * .isWhite .slickNext, * .isWhite span, * .isWhite .asideText, * .isWhite i, * .isWhite .icon-search {
  color: black !important;
}

* .isDark {
  background: black !important;
}

* .isDark h1, * .isDark h2, * .isDark article, * .isDark article p, * .isDark a, * .isDark .slickPrev, * .isDark .slickNext, * .isDark span, * .isDark .asideText, * .isDark i, * .isDark .icon-search {
  color: white !important;
}

* .isDark .searchBar {
  background: black !important;
}

* .isDark .showSearchBar {
  border-color: white !important;
  color: white !important;
}

* .isDark .nav-right ul li a {
  color: #818181 !important;
}

* .isDark .nav-right ul li a:hover {
  color: white !important;
}

* .isDark .codirMemberInput p span {
  color: white !important;
}

* .isDark .btnSkew div {
  background-color: black !important;
}

* .isDark .btnSkew div span {
  color: white !important;
  font-style: normal;
}

* .isDark .btnSkew div span:hover {
  color: black !important;
}

* .isDark .containerImgSkew2 p {
  color: black !important;
}

* .isDark .containerImgSkew2 p span {
  color: black !important;
}

* .isDark .containerQuestionAnswer article p {
  color: black !important;
}

* .isDark .contactInfo p {
  color: white !important;
}

* .isDark .icon-burger-close {
  background-image: url("/images/burger-white.svg") !important;
}

* .isDark .icon-search {
  background-image: url("/images/loupewhite.png") !important;
}

* .isDark .translationSelection {
  background-color: black !important;
}

* .isDark .navFooter h3, * .isDark .navFooterOther h3, * .isDark .navJuridic h3 {
  color: white !important;
}

* .isDark .chevronLeft {
  background-image: url("/images/returnWhite.png");
}

body {
  font-family: 'Poppins', Poppins Light, Poppins Regular, Poppins SemiBold, Poppins ExtraBold;
  margin: 0;
  box-sizing: border-box;
}

body .containerToHide {
  display: none !important;
}

body.hasOpenedModal {
  overflow: hidden;
}

h1, h2 h3, h4, h5, h6 {
  margin: 0;
}

textarea, select, input, button {
  outline: none;
}

.teamPage {
  display: flex;
  flex-direction: column;
}

header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  background: white;
  width: 100%;
  z-index: 10;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
}

header .logo {
  align-self: center;
  margin: 0 10px 0 50px;
  width: 0;
  display: flex;
}

header .logo a {
  height: 65px;
}

header .logo a img {
  max-height: 65px;
}

header .nav {
  align-self: center;
  margin: 0 50px 0 10px;
}

@media (max-width: 769px) {
  header .logo, header .nav {
    margin: 0 10px;
  }
}

header .nav {
  display: flex;
  align-items: center;
}

header .nav i.fas {
  font-size: 20px;
  padding: 28px 5px;
}

@media (max-width: 425px) {
  header .nav i.fas, header .nav span {
    display: none;
  }
}

header .nav span {
  margin: 0 10px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

header .nav form {
  display: flex;
  justify-content: flex-end;
  position: relative;
  left: 35px;
}

header .nav form .searchBar {
  background: #fff;
  z-index: 1000;
  font-size: 18px;
  padding: 0;
  border: none;
  border-bottom: 1.5px solid #fff;
  transition: .3s;
  width: 0;
  padding: 0;
  height: 40px;
}

header .nav form .showSearchBar {
  width: 100%;
  border-color: #000;
}

header .nav .icon-search {
  width: 22px;
  height: 22px;
  margin: 0 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/loop.png");
}

header .nav .icon-burger-close {
  width: 46px;
  height: 26px;
  margin: 0 35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/burger-black.svg");
}

@media (max-width: 476px) {
  header .nav .icon-burger-close {
    margin: 0 10px;
  }
}

header .nav-right {
  display: flex;
  width: 620px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -620px;
  overflow-x: hidden;
  transition: .4s;
  z-index: 99999;
  background-color: #000;
}

@media (max-width: 769px) {
  header .nav-right {
    width: 620px;
    right: -620px;
  }
}

header .nav-right .icon-burger-open {
  display: flex;
  width: 31px;
  height: 31px;
  position: absolute;
  right: 87px;
  top: 30px;
  cursor: pointer;
  background-image: url("/images/svg/burger_menu_close.svg");
  color: #fff;
  font-size: 50px;
}

@media (max-width: 769px) {
  header .nav-right .icon-burger-open {
    right: 30px;
  }
}

header .nav-right ul {
  list-style-type: none;
  vertical-align: middle;
  display: inline-block;
  margin: auto 0;
  width: 100%;
}

header .nav-right ul li {
  padding: 0 0 40px 60px;
}

@media (max-width: 769px) {
  header .nav-right ul li {
    padding-left: 20px;
  }
}

header .nav-right ul li a {
  padding: 0;
  text-decoration: none;
  font-size: 35px;
  display: block;
  transition: .3s;
  text-transform: lowercase;
  font-family: 'Poppins', sans-serif;
  color: #818181 !important;
  font-weight: 600;
  transition: .3s;
  cursor: pointer;
}

header .nav-right ul li a:hover {
  color: #fff !important;
}

header .translationSelection {
  display: none;
  width: 100px;
  height: 80px;
  background: #fff;
  position: absolute;
  right: 10px;
  top: 60px;
}

header .translationSelection ul {
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  list-style: none;
  text-transform: uppercase;
  font-size: 16px;
}

header .translationSelection ul li {
  align-self: center;
}

header .translationSelection ul li a {
  text-decoration: none;
  color: #000;
}

header .showTranslation {
  display: flex;
  z-index: 1;
}

.searchContainer {
  display: none;
  padding-top: 115px;
  flex-direction: column;
  margin: 0 7.5%;
}

.searchContainer .titleResult, .searchContainer .countSearch {
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 27px;
}

.searchContainer .searchResultContainer {
  display: flex;
  flex-wrap: wrap;
}

.searchContainer .searchResultContainer a {
  flex: 0 0 20%;
  text-decoration: none;
  color: #000;
}

@media (max-width: 1441px) {
  .searchContainer .searchResultContainer a {
    flex: 0 0 25%;
  }
}

@media (max-width: 1025px) {
  .searchContainer .searchResultContainer a {
    flex: 0 0 33%;
  }
}

@media (max-width: 769px) {
  .searchContainer .searchResultContainer a {
    flex: 0 0 50%;
  }
}

@media (max-width: 545px) {
  .searchContainer .searchResultContainer a {
    flex: 0 0 100%;
  }
}

.searchContainer .searchResultContainer a .containerOneResult {
  margin: 0 15px 48px 15px;
}

.searchContainer .searchResultContainer a .containerOneResult .cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 280px;
  max-width: 220px;
  transition: .3s;
}

.searchContainer .searchResultContainer a .containerOneResult .cover:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 545px) {
  .searchContainer .searchResultContainer a .containerOneResult .cover {
    margin: auto;
  }
}

@media (max-width: 545px) {
  .searchContainer .searchResultContainer a .containerOneResult div {
    text-align: center;
  }
}

.searchContainer .searchResultContainer a .containerOneResult div p {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}

footer {
  color: #000;
  padding: 50px 10%;
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.06);
  padding: 3% 7% 1.5% 7%;
}

footer .containerFooter {
  display: flex;
}

@media (max-width: 820px) {
  footer .containerFooter {
    flex-direction: column;
  }
}

footer .containerFooter .contactInfo {
  margin-right: 10%;
}

@media (max-width: 1024px) {
  footer .containerFooter .contactInfo {
    margin-right: 5%;
  }
}

@media (max-width: 769px) {
  footer .containerFooter .contactInfo {
    order: 5;
  }
}

footer .containerFooter .contactInfo .logo-footer img {
  max-height: 70px;
}

footer .containerFooter .contactInfo p {
  font-size: 14px;
  font-style: normal;
}

footer .containerFooter .contactInfo .rsList {
  font-size: 20px;
}

footer .containerFooter .contactInfo .rsList a {
  margin-right: 10px;
}

footer .containerFooter .contactInfo .rsList a i {
  color: #000;
}

footer .containerFooter .navFooter, footer .containerFooter .navJuridic, footer .containerFooter .navFooterOther {
  margin: 0;
  margin-right: 19%;
}

@media (max-width: 1440px) {
  footer .containerFooter .navFooter, footer .containerFooter .navJuridic, footer .containerFooter .navFooterOther {
    margin-right: 10%;
  }
}

@media (max-width: 769px) {
  footer .containerFooter .navFooter, footer .containerFooter .navJuridic, footer .containerFooter .navFooterOther {
    order: 5;
  }
}

footer .containerFooter .navFooter h3, footer .containerFooter .navJuridic h3, footer .containerFooter .navFooterOther h3 {
  margin: 0 0 15px 0;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}

@media (max-width: 769px) {
  footer .containerFooter .navFooter h3, footer .containerFooter .navJuridic h3, footer .containerFooter .navFooterOther h3 {
    margin: 15px 0 0 0;
  }
}

footer .containerFooter .navFooter ul, footer .containerFooter .navJuridic ul, footer .containerFooter .navFooterOther ul {
  list-style: none;
  margin: unset;
  padding: 0;
}

footer .containerFooter .navFooter ul li, footer .containerFooter .navJuridic ul li, footer .containerFooter .navFooterOther ul li {
  margin-bottom: -3px;
}

footer .containerFooter .navFooter ul li:hover, footer .containerFooter .navJuridic ul li:hover, footer .containerFooter .navFooterOther ul li:hover {
  text-decoration: underline;
}

footer .containerFooter .navFooter ul li a, footer .containerFooter .navJuridic ul li a, footer .containerFooter .navFooterOther ul li a {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  text-decoration: none;
  font-style: normal;
}

@media (max-width: 1024px) {
  footer .containerFooter .navFooter, footer .containerFooter .navJuridic, footer .containerFooter .navFooterOther {
    margin-right: 5%;
  }
}

@media (max-width: 769px) {
  footer .containerFooter .navFooterOther {
    margin-bottom: 20px;
  }
}

@media (max-width: 820px) {
  footer .containerFooter .newsletterContainer {
    display: flex;
    padding: 30px;
    margin-right: 0;
    order: 0;
  }
}

@media (max-width: 769px) {
  footer .containerFooter .newsletterContainer {
    padding: 30px 0;
  }
}

@media (max-width: 769px) {
  footer .containerFooter .newsletterContainer .btn-footer {
    width: 95%;
  }
}

footer #footerLogo {
  float: right;
  margin-bottom: 10px;
  margin-top: -38px;
}

@media (max-width: 769px) {
  footer #footerLogo {
    margin-top: 5px;
  }
}

.containerErrorPage {
  padding: 80px 40px;
  background-image: url("/images/404.png");
  background-size: auto 50%;
  height: 550px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-color: #000;
}

@media (max-width: 1025px) {
  .containerErrorPage {
    background-size: auto 40%;
  }
}

@media (max-width: 676px) {
  .containerErrorPage {
    background-size: auto 35%;
    height: 390px;
  }
}

@media (max-width: 576px) {
  .containerErrorPage {
    background-size: auto 30%;
  }
}

@media (max-width: 426px) {
  .containerErrorPage {
    background-size: auto 20%;
    height: 360px;
  }
}

.containerErrorPage .containerErrorContent {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  margin-top: 40px;
}

.containerErrorPage .containerErrorContent .containerTitleError {
  color: #fff;
}

.containerErrorPage .containerErrorContent .containerTitleError p {
  font-size: 13px;
  font-weight: 200;
}

.containerErrorPage .containerErrorContent .containerTitleError a {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}

.containerErrorPage .containerErrorContent .containerTitleError a:hover {
  text-decoration: underline;
}

.containerModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.containerModal .modal {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 70px auto 20px auto;
  background: #fff;
  border-radius: 4px;
  max-width: 780px;
  padding-bottom: 50px;
  padding-top: 0;
}

@media (max-width: 425px) {
  .containerModal .modal {
    max-width: 90%;
  }
}

.containerModal .modal .logo img {
  margin: 10px auto;
  max-height: 55px;
}

.containerModal .modal h3 {
  font-size: 25px;
  width: 90%;
  margin: 10px auto 20px auto;
  font-weight: 700;
}

.containerModal .modal input {
  width: 70%;
  height: 40px;
  padding: 5px 20px;
  font-size: 18px;
  border: 1px solid #d6d6d6;
  color: #000;
  border-radius: 4px;
}

.containerModal .modal .btnSkew {
  width: 40%;
  margin: 20px auto 5px auto;
}

.containerModal .modal a.knowMore {
  display: inline-block;
  font-weight: 500;
  margin: 10px auto 50px auto;
  font-size: 16px;
  color: #000;
  text-decoration: none;
}

.containerModal .modal p {
  margin: 0 20px;
  max-height: 200px;
  overflow: auto;
}

#rgpdModal {
  display: none;
}

#rgpdModal .modal {
  display: flex;
  margin-top: 100px;
  padding: 20px 0;
}

@media (max-width: 769px) {
  #rgpdModal .modal {
    margin: 20px;
  }
}

#rgpdModal .modal .containerBtn {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 520px) {
  #rgpdModal .modal .containerBtn {
    flex-direction: column;
  }
}

#rgpdModal .modal .containerBtn .btnSkew {
  margin: 0 10px;
}

@media (max-width: 520px) {
  #rgpdModal .modal .containerBtn .btnSkew {
    width: 70%;
    margin: 10px auto;
  }
}

#rgpdModal .modal .containerBtn .denied div span:hover {
  color: #fff;
}

#rgpdModal .rgdpUseLink {
  color: #000;
  margin-bottom: 60px;
}

#steeringCommitteeModal {
  overflow: auto;
}

#steeringCommitteeModal .modal {
  max-width: 55%;
  border-radius: 4px;
  padding-bottom: 10px;
  padding-top: 10px;
}

@media (max-width: 1600px) {
  #steeringCommitteeModal .modal {
    max-width: 65%;
  }
}

@media (max-width: 765px) {
  #steeringCommitteeModal .modal {
    max-width: 90%;
  }
}

#steeringCommitteeModal .modal .containerInterview {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  font-size: 21px;
}

#steeringCommitteeModal .modal .containerInterview .containerContInter {
  display: flex;
  margin: 0 90px;
}

@media (max-width: 1440px) {
  #steeringCommitteeModal .modal .containerInterview .containerContInter {
    flex-direction: column;
    margin: 0 20px;
  }
}

#steeringCommitteeModal .modal .containerInterview .containerContInter .containerImgSkew2 {
  display: flex;
  overflow: hidden;
  width: 340px;
  padding: 10px;
  z-index: 0;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1440px) {
  #steeringCommitteeModal .modal .containerInterview .containerContInter .containerImgSkew2 {
    width: 95%;
    flex: inherit;
  }
}

#steeringCommitteeModal .modal .containerInterview .containerContInter .containerImgSkew2 .imgSkew {
  display: flex;
  background-image: url("/images/img-home.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  clip-path: polygon(0px 30px, 270px 30px, 340px 260px, 100px 260px, 70px 260px);
  align-self: center;
  width: 340px;
  height: 285px;
}

@media (max-width: 520px) {
  #steeringCommitteeModal .modal .containerInterview .containerContInter .containerImgSkew2 .imgSkew {
    width: 270px;
    clip-path: polygon(0px 30px, 210px 30px, 270px 260px, 100px 260px, 70px 260px);
  }
}

#steeringCommitteeModal .modal .containerInterview .containerContInter .containerImgSkew2 p {
  margin: 0;
  margin-left: 20%;
  margin-bottom: 20px;
  font-weight: 500;
}

#steeringCommitteeModal .modal .containerInterview .containerContInter .containerImgSkew2 p span {
  font-weight: 700;
}

#steeringCommitteeModal .modal .containerInterview .containerContInter .containerQuestionAnswer {
  justify-content: center;
  flex: 1;
  margin: 10px;
  display: flex;
  text-align: justify;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 40px;
}

@media (max-width: 1440px) {
  #steeringCommitteeModal .modal .containerInterview .containerContInter .containerQuestionAnswer {
    padding-top: 0;
  }
}

#steeringCommitteeModal .modal .containerInterview .containerContInter .containerQuestionAnswer article {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 14px;
}

#steeringCommitteeModal .modal .containerInterview .containerContInter .containerQuestionAnswer article p {
  max-height: unset;
}

#steeringCommitteeModal .modal .containerInterview h3 {
  font-size: 14px;
  margin: 0;
}

@media (max-width: 1440px) {
  #steeringCommitteeModal .modal .containerInterview h3 {
    margin-bottom: 10px;
  }
}

#steeringCommitteeModal .modal .containerInterview p {
  font-size: 14px;
  margin: 0;
}

#newsletters img {
  margin: 40px auto 0px auto;
  max-height: 100px;
}

.btnSkew {
  display: block;
  height: 45px;
  width: calc(100% + 30px);
  padding: 3px;
  transform: skew(20deg);
  background: linear-gradient(to right, #5e00d4, #f00);
  text-align: center;
  border: none;
  cursor: pointer;
}

.btnSkew div {
  display: block;
  text-align: center;
  background-color: #fff;
}

.btnSkew div span {
  display: block;
  height: 35px;
  padding: 10px 15px 0px 15px;
  transform: skew(-20deg);
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  white-space: nowrap;
  text-decoration: none;
}

.btnSkew div:hover {
  background: none !important;
}

.btnSkew div:hover span {
  color: #fff !important;
}

.sectionTitleHome {
  width: 100%;
  background-color: black;
  overflow: hidden;
  /*
    @media (max-width: 769px) {
        max-height: 550px;
    }*/
}

.sectionTitleHome .containerTitleHome {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  position: relative;
  max-height: 450px;
  min-height: 450px;
  text-align: center;
}

.sectionTitleHome .containerTitleHome .text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  padding-left: 8%;
  text-align: left;
}

@media (max-width: 769px) {
  .sectionTitleHome .containerTitleHome .text {
    padding-right: 12%;
    padding-left: 5%;
    width: inherit;
  }
}

.sectionTitleHome .containerTitleHome .text .titleInfo {
  font-weight: 700;
  font-size: 21px;
  color: #ff0000;
}

.sectionTitleHome .containerTitleHome .text article p {
  font-weight: 400;
  font-size: 14px;
}

.sectionTitleHome .containerTitleHome .text h1 {
  text-transform: uppercase;
  font-size: 70px;
  line-height: 58px;
  font-weight: 800;
  margin-bottom: 40px;
}

@media (max-width: 921px) {
  .sectionTitleHome .containerTitleHome .text h1 {
    font-size: 48px;
    line-height: 41px;
  }
}

@media (max-width: 650px) {
  .sectionTitleHome .containerTitleHome .text h1 {
    font-size: 38px;
  }
}

.sectionTitleHome .containerTitleHome .text .btnSkew {
  width: 250px;
  cursor: pointer;
}

.sectionTitleHome .containerTitleHome .text .btnSkew div {
  background-color: #000;
  color: #fff;
  max-height: 45px;
}

.sectionTitleHome .containerTitleHome .text .btnSkew div span {
  padding: 13px 0;
  color: #fff;
}

.sectionTitleHome .containerTitleHome .text .blackTitle {
  color: black;
}

.sectionTitleHome .containerTitleHome .text .whiteTitle {
  color: white;
}

.sectionTitleHome .containerTitleHome img {
  height: auto;
  margin-bottom: -7px;
  width: 100%;
}

@media (max-width: 921px) {
  .sectionTitleHome .containerTitleHome img {
    clip-path: none;
    position: unset;
  }
}

.sectionTitleHome .containerTitleHomeVideo {
  color: #fff;
  position: relative;
  max-height: 450px;
  min-height: 450px;
  text-align: left;
  display: flex;
  padding: 0 5%;
  align-items: center;
}

@media (max-width: 769px) {
  .sectionTitleHome .containerTitleHomeVideo {
    flex-direction: column;
    max-height: none;
  }
}

.sectionTitleHome .containerTitleHomeVideo .text {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

@media (max-width: 769px) {
  .sectionTitleHome .containerTitleHomeVideo .text {
    margin-top: 10px;
  }
}

.sectionTitleHome .containerTitleHomeVideo .text .titleInfo {
  font-weight: 700;
  font-size: 21px;
  color: #ff0000;
}

.sectionTitleHome .containerTitleHomeVideo .text article {
  overflow: auto;
  margin: 0 0 20px 0;
}

.sectionTitleHome .containerTitleHomeVideo .text article p {
  font-weight: 500;
}

.sectionTitleHome .containerTitleHomeVideo .text h1 {
  text-transform: uppercase;
  font-size: 70px;
  line-height: 58px;
  margin-bottom: 10px;
}

@media (max-width: 921px) {
  .sectionTitleHome .containerTitleHomeVideo .text h1 {
    font-size: 48px;
    line-height: 51px;
  }
}

@media (max-width: 650px) {
  .sectionTitleHome .containerTitleHomeVideo .text h1 {
    font-size: 35px;
  }
}

.sectionTitleHome .containerTitleHomeVideo .text .btnSkew {
  width: 250px;
  cursor: pointer;
}

.sectionTitleHome .containerTitleHomeVideo .text .btnSkew div {
  background-color: #000;
  color: #fff;
  max-height: 45px;
}

.sectionTitleHome .containerTitleHomeVideo .text .btnSkew div span {
  padding: 13px 0;
  color: #fff;
}

.sectionTitleHome .containerTitleHomeVideo .text .blackTitle {
  color: black;
}

.sectionTitleHome .containerTitleHomeVideo .text .whiteTitle {
  color: white;
}

.sectionTitleHome .containerTitleHomeVideo img {
  display: flex;
  margin-left: 20px;
  width: 50%;
}

@media (max-width: 769px) {
  .sectionTitleHome .containerTitleHomeVideo img {
    width: 100%;
  }
}

.sectionTitleHome .containerTitleHomeVideo .noText {
  flex: unset;
}

.sectionTitleHome .containerTitleHomeVideo .noTextImg {
  width: 100%;
}

.sectionTitleHome .containerTitleHomeVideo .containerImgVideo {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: 500px;
}

.sectionTitleHome .containerTitleHomeVideo .containerImgVideo .imgVideoInput {
  width: 70%;
  max-height: 400px;
}

.sectionTitleHome .containerTitleHomeVideo .containerImgVideo .imgVideoInputIcon {
  position: absolute;
  width: 120px;
}

.sectionTitleHome .noDesc {
  min-height: auto;
}

.sectionTitleHome .noDesc .text .btnSkew {
  margin-top: 30px;
  cursor: pointer;
}

.sectionTitleHome .noDesc .text .btnSkew div {
  max-height: 45px;
}

@media (max-width: 650px) {
  .sectionTitleHome .noDesc .text .btnSkew {
    margin-top: 15px;
    width: 200px;
  }
  .sectionTitleHome .noDesc .text .btnSkew div span {
    padding: 3px 0;
    color: #fff;
  }
}

.sectionTitleHome a {
  text-decoration: none;
}

.modalTitleHome .modal {
  background: none;
  max-width: 950px;
}

.sectionTitleEquipe {
  max-height: 580px;
}

.sectionTitleEquipe .containerTitleHome, .sectionTitleEquipe .containerTitleHomeVideo {
  max-height: 580px;
  min-height: 580px;
}

.sectionTitleEquipe .containerTitleHome .text, .sectionTitleEquipe .containerTitleHomeVideo .text {
  top: 50%;
}

@media (max-width: 1024px) {
  .sectionTitleEquipe .containerTitleHome .text, .sectionTitleEquipe .containerTitleHomeVideo .text {
    top: 50%;
  }
}

.sectionTitleEquipe .containerTitleHome .text h1, .sectionTitleEquipe .containerTitleHomeVideo .text h1 {
  font-size: 70px;
}

@media (max-width: 1441px) {
  .sectionTitleEquipe .containerTitleHome .text h1, .sectionTitleEquipe .containerTitleHomeVideo .text h1 {
    font-size: 60px;
  }
}

@media (max-width: 1024px) {
  .sectionTitleEquipe .containerTitleHome .text h1, .sectionTitleEquipe .containerTitleHomeVideo .text h1 {
    font-size: 55px;
  }
}

@media (max-width: 769px) {
  .sectionTitleEquipe .containerTitleHome .text h1, .sectionTitleEquipe .containerTitleHomeVideo .text h1 {
    font-size: 42px;
  }
}

.sectionTitleEquipe .noDesc {
  max-height: 580px;
  min-height: auto;
}

.sectionPropos {
  font-family: 'Poppins', sans-serif;
  display: flex;
  overflow: hidden;
}

@media (max-width: 1025px) {
  .sectionPropos {
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }
}

.sectionPropos .containerHome {
  margin: 3.5% 5% 4.5% 8%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (max-width: 769px) {
  .sectionPropos .containerHome {
    margin: 40px 30px;
    width: 90%;
  }
}

@media (max-width: 521px) {
  .sectionPropos .containerHome {
    margin-left: 0;
    margin-right: 0;
  }
}

.sectionPropos .containerHome .title {
  color: #000;
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
  line-height: 70px;
  margin-bottom: 30px;
}

@media (max-width: 1441px) {
  .sectionPropos .containerHome .title {
    margin-top: 10px;
  }
}

@media (max-width: 769px) {
  .sectionPropos .containerHome .title {
    margin-top: 0;
    font-size: 45px;
  }
}

@media (max-width: 426px) {
  .sectionPropos .containerHome .title {
    font-size: 35px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.sectionPropos .containerHome .content {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 30px;
}

.sectionPropos .containerHome .content p {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 426px) {
  .sectionPropos .containerHome .content {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.sectionPropos .containerHome a {
  text-decoration: none;
}

@media (max-width: 769px) {
  .sectionPropos .containerHome a {
    order: 1;
  }
}

.sectionPropos .containerHome a .btnSkew {
  width: 264px;
}

@media (max-width: 769px) {
  .sectionPropos .containerHome a .btnSkew {
    margin-top: 30px;
    width: 100%;
  }
}

@media (max-width: 426px) {
  .sectionPropos .containerHome a {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.sectionPropos .containerImgSkew {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex: 1;
  width: 500px;
}

.sectionPropos .containerImgSkew .imgSkew {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  clip-path: polygon(0 0, 370px 0, 415px 240px, 500px 730px, 130px 650px);
  flex: 1;
  align-self: center;
  height: 280px;
  margin-left: 28%;
}

@media (max-width: 1441px) {
  .sectionPropos .containerImgSkew .imgSkew {
    margin-left: 10%;
  }
}

@media (max-width: 1025px) {
  .sectionPropos .containerImgSkew .imgSkew {
    margin-left: 15%;
  }
}

@media (max-width: 926px) {
  .sectionPropos .containerImgSkew .imgSkew {
    margin-left: 10%;
  }
}

@media (max-width: 521px) {
  .sectionPropos .containerImgSkew .imgSkew {
    clip-path: polygon(40px 0px, 330px 0, 375px 280px, 560px 720px, 150px 650px);
  }
}

.sectionPropos .containerImgSkewMobile {
  display: none;
  margin-top: 50px;
  height: 220px;
}

.sectionPropos .containerImgSkewMobile .imgSkew {
  display: flex;
  background-image: url("/images/img-home.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 100% 100%, 30% 110%);
  flex: 1;
  align-self: center;
  height: 200px;
}

@media (max-width: 426px) {
  .sectionPropos .containerImgSkewMobile {
    margin-top: 0;
    display: flex;
    margin-left: 30px;
  }
}

.sectionProposDuo .containerSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sectionProposDuo .containerSection .titleSection {
  display: flex;
  color: #000;
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 3.5% 5% 30px 8%;
}

@media (max-width: 1441px) {
  .sectionProposDuo .containerSection .titleSection {
    margin-top: 10px;
    font-size: 45px;
  }
}

@media (max-width: 926px) {
  .sectionProposDuo .containerSection .titleSection {
    margin-bottom: 10px;
  }
}

@media (max-width: 769px) {
  .sectionProposDuo .containerSection .titleSection {
    margin: 10px 30px;
  }
}

@media (max-width: 426px) {
  .sectionProposDuo .containerSection .titleSection {
    font-size: 38px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.sectionProposDuo .containerSection .containerLine {
  display: flex;
  overflow: hidden;
}

@media (max-width: 926px) {
  .sectionProposDuo .containerSection .containerLine {
    flex-direction: column;
  }
}

.sectionProposDuo .containerSection .containerLine .containerLeft {
  flex: 1;
  transition: .3s;
  background: white;
}

.sectionProposDuo .containerSection .containerLine .containerLeft:hover {
  background-color: black;
}

.sectionProposDuo .containerSection .containerLine .containerRight {
  flex: 1;
  transition: .3s;
  background: white;
}

.sectionProposDuo .containerSection .containerLine .containerRight:hover {
  background-color: black;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 60px 80px;
  /*background-color: red;*/
  transform: skewX(-20deg);
  transition: .3s;
  background: white;
  min-height: 280px;
}

@media (max-width: 926px) {
  .sectionProposDuo .containerSection .containerLine .containerTextSection {
    transform: none;
    padding: 40px 80px;
  }
}

@media (max-width: 426px) {
  .sectionProposDuo .containerSection .containerLine .containerTextSection {
    padding: 20px 40px;
  }
}

.sectionProposDuo .containerSection .containerLine .containerTextSection h1 {
  margin: 0;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection:hover {
  background-color: black;
  color: white !important;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection:hover h1 {
  color: white;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection:hover .btnSkew div {
  background-color: black;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection:hover .btnSkew div span {
  color: white;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection:hover article {
  color: white !important;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection img {
  display: flex;
  max-width: 300px;
  width: 100%;
  min-height: 175px;
  padding: 10px;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: skewX(20deg);
}

@media (max-width: 926px) {
  .sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew {
    transform: none;
  }
}

.sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew article {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 30px;
  max-height: 120px;
  overflow: auto;
  scrollbar-width: none;
  max-width: 70%;
  text-align: center;
}

@media (max-width: 769px) {
  .sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew article {
    margin-bottom: 5px;
  }
}

.sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew article::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew article p {
  display: flex;
}

.sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew a {
  text-decoration: none;
}

@media (max-width: 769px) {
  .sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew a {
    order: 1;
  }
}

.sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew a .btnSkew {
  width: 264px;
}

@media (max-width: 769px) {
  .sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew a .btnSkew {
    margin-top: 30px;
    width: 100%;
  }
}

@media (max-width: 426px) {
  .sectionProposDuo .containerSection .containerLine .containerTextSection .containerSkew a {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.partners {
  font-family: 'Poppins', sans-serif;
}

.partners .title {
  color: #000;
  font-size: 70px;
  font-weight: 800;
  line-height: 58px;
  text-transform: uppercase;
  margin: 0;
  padding: 3% 7% 1% 7%;
  text-align: left;
  font-style: normal;
}

@media (max-width: 1200px) {
  .partners .title {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (max-width: 769px) {
  .partners .title {
    padding: 3% 5% 1% 5%;
    font-size: 50px;
    line-height: 48px;
  }
}

@media (max-width: 521px) {
  .partners .title {
    font-size: 38px;
    line-height: 36px;
  }
}

@media (max-width: 376px) {
  .partners .title {
    font-size: 30px;
    line-height: 28px;
  }
}

.partners .autoplay {
  display: flex;
  padding: 1% 10%;
  align-items: center;
  height: 180px;
}

@media (max-width: 769px) {
  .partners .autoplay {
    margin: 0 30px;
  }
}

@media (max-width: 521px) {
  .partners .autoplay {
    margin: 0 10px;
  }
}

.partners .autoplay .slick-list {
  width: 100%;
  flex: 1;
}

.partners .autoplay i {
  font-size: 30px;
}

.partners .autoplay .slickPrev {
  margin-right: 40px;
  cursor: pointer;
}

.partners .autoplay .slickNext {
  margin-left: 40px;
  cursor: pointer;
}

.partners .autoplay a {
  text-decoration: none;
  outline: none !important;
}

.partners .autoplay a .slickPartners {
  min-height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 115px;
  justify-content: space-between;
}

.partners .autoplay a .slickPartners p {
  font-size: 14px;
  color: black;
  justify-self: flex-end;
}

.partners .autoplay .slickPartners > img {
  display: flex;
  margin: auto;
  max-height: 65px;
  max-width: 100%;
  max-width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  max-width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  max-width: fill-available;
}

.partners .partnerInfo {
  text-align: center;
  margin-bottom: 50px;
}

.sectionLastProductions {
  display: flex;
  flex-direction: column;
}

.sectionLastProductions a {
  text-decoration: none;
}

.sectionLastProductions a h2 {
  width: 80%;
  margin: 3.5% 7% 2.5% 7%;
  font-size: 70px;
  color: #fff;
  text-transform: uppercase;
  line-height: 58px;
  font-weight: 800;
  font-style: normal;
}

@media (max-width: 769px) {
  .sectionLastProductions a h2 {
    margin: 30px;
    font-size: 50px;
    line-height: 48px;
  }
}

@media (max-width: 520px) {
  .sectionLastProductions a h2 {
    font-size: 35px;
    line-height: 38px;
    width: auto;
  }
}

.sectionLastProductions .containerLastProduction {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}

@media (max-width: 1224px) {
  .sectionLastProductions .containerLastProduction {
    display: none;
  }
}

.sectionLastProductions .containerLastProduction a {
  display: flex;
  flex: 1;
  text-decoration: none;
  transform: skewX(20deg);
  transition: 1s ease-in-out;
}

.sectionLastProductions .containerLastProduction a:hover {
  flex: 1.1;
}

.sectionLastProductions .containerLastProduction a:hover .cardSkew {
  opacity: 1;
  border-image-source: linear-gradient(85deg, #7500ff, #f3005e);
  border-left: 2px solid;
  border-right: 2px solid;
}

.sectionLastProductions .containerLastProduction a .cardSkew {
  flex: 1;
  overflow: hidden;
  opacity: .3;
  border-left: 0px solid;
  border-right: 0px solid;
  border-image-slice: 1;
  border-color: #000;
  width: 0;
  pointer-events: none;
}

.sectionLastProductions .containerLastProduction a .cardSkew .slideSkew {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(11vh + 200%);
  transform: skew(-20deg) translate(-35%, 0px);
  flex: 1;
  background-image: url("/images/img-home.jpeg");
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.sectionLastProductions .containerLastProduction a .cardSkew .slideSkew p {
  opacity: 0;
}

.sectionLastProductions .containerLastProduction .textCard {
  pointer-events: none;
  user-select: none;
  transition: .7s ease-in-out;
  position: absolute;
  width: 260px;
  height: 0;
  opacity: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  z-index: 1;
  font-style: normal;
}

.sectionLastProductions .lastProdMobile {
  display: none;
  width: 100%;
  max-height: 500px;
}

.sectionLastProductions .lastProdMobile .containerCardMobile a {
  width: 100%;
}

@media (max-width: 1224px) {
  .sectionLastProductions .lastProdMobile {
    display: block;
  }
  .sectionLastProductions .lastProdMobile .cardSkew .slideSkew {
    transform: skew(-20deg) translate(-45%, 0px) !important;
  }
  .sectionLastProductions .lastProdMobile .autoplayLastProd {
    width: auto;
  }
  .sectionLastProductions .lastProdMobile .slick-current .cardSkew {
    opacity: 1 !important;
    border-image-source: linear-gradient(85deg, #7500ff, #f3005e);
    border-left: 2px solid;
    border-right: 2px solid;
  }
  .sectionLastProductions .lastProdMobile .slick-current .textCard {
    opacity: 1 !important;
    top: 40%;
    margin-left: -50px;
  }
}

@media (max-width: 1224px) and (max-width: 376px) {
  .sectionLastProductions .lastProdMobile .slick-current .textCard {
    margin-left: -120px;
  }
}

.sectionLastProductions .lastProdDesk {
  display: block;
}

@media (max-width: 1224px) {
  .sectionLastProductions .lastProdDesk {
    display: none;
  }
}

.sectionLastProductions .lastProdDesk .autoplayLastProdArrow .slickPrev {
  font-size: 40px;
  color: white;
  position: absolute;
  bottom: 50%;
  left: 0;
  margin-left: 30px;
  z-index: 999;
}

.sectionLastProductions .lastProdDesk .autoplayLastProdArrow .slickNext {
  font-size: 40px;
  color: white;
  position: absolute;
  margin-right: 30px;
  bottom: 50%;
  right: 0;
}

.slick-prev:before {
  color: transparent;
}

#slick_partner .slick-next:before {
  color: transparent;
}

.slick-prev {
  background-image: url("/images/prev.png");
  background-repeat: no-repeat;
  opacity: 1;
  background-size: 60% 100%;
}

.slick-next {
  background-image: url("/images/next.png");
  background-repeat: no-repeat;
  opacity: 1;
  background-size: 60% 100%;
}

.slick-prev,
.slick-next {
  width: 80px;
  height: 45px;
}

.slick-prev {
  left: -140px;
}

.slick-next {
  right: -140px;
}

.listActualite {
  display: flex;
  flex-direction: column;
}

.listActualite h1.title {
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 900;
  line-height: 55px;
  max-width: 100%;
  padding: 60px 10% 0px 10%;
}

@media (max-width: 920px) {
  .listActualite h1.title {
    font-size: 50px;
    line-height: 47px;
    padding-left: 0;
  }
}

@media (max-width: 520px) {
  .listActualite h1.title {
    font-size: 38px;
    line-height: 36px;
  }
}

.listActualite .containerCont {
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin: 20px 50px 30px 50px;
}

@media (max-width: 920px) {
  .listActualite .containerCont {
    flex-direction: column;
    margin: 20px 20px 30px 20px;
  }
}

.listActualite .containerCont .contents {
  display: flex;
  flex: 1;
  align-self: center;
  margin: 0 2% 0 5%;
  padding: 0 15%;
}

.listActualite .containerCont .sortBy {
  margin-right: 5%;
  align-self: center;
}

@media (max-width: 920px) {
  .listActualite .containerCont .sortBy {
    align-self: flex-end;
  }
}

.listActualite .containerActu {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

@media (max-width: 1560px) {
  .listActualite .containerActu {
    max-width: 90%;
    width: 90%;
  }
}

@media (max-width: 1080px) {
  .listActualite .containerActu {
    max-width: 100%;
    width: 100%;
  }
}

.listActualite .containerActu:after {
  content: "";
  width: 320px;
}

.listActualite .containerActu .columnActu {
  width: 100%;
  position: relative;
}

@media (min-width: 576px) {
  .listActualite .containerActu .columnActu {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .listActualite .containerActu .columnActu {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .listActualite .containerActu .columnActu {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
}

@media (min-width: 1440px) {
  .listActualite .containerActu .columnActu {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.listActualite .containerActu .columnActu a {
  max-width: 320px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}

.listActualite .containerActu .columnActu a .actue {
  margin-bottom: 30px;
  width: 95%;
  min-width: 260px;
}

@media (max-width: 576px) {
  .listActualite .containerActu .columnActu a .actue {
    width: 100%;
  }
}

.listActualite .containerActu .columnActu a .actue div.containerBackgroundImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  padding-bottom: 5px;
  height: 430px;
  max-height: 430px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  transition: .3s;
}

.listActualite .containerActu .columnActu a .actue div.containerBackgroundImg:hover {
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.listActualite .containerActu .columnActu a .actue .infoActu {
  max-width: 320px;
}

.listActualite .containerActu .columnActu a .actue .createdAt, .listActualite .containerActu .columnActu a .actue .title, .listActualite .containerActu .columnActu a .actue .contents, .listActualite .containerActu .columnActu a .actue .knowMore {
  align-self: flex-start;
  color: black;
  font-size: 14px;
}

.listActualite .containerActu .columnActu a .actue .title {
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: 15px;
  font-size: 21px;
  padding-bottom: 0px;
  word-break: break-word;
}

.listActualite .containerActu .columnActu a .actue .createdAt {
  margin: 0;
  padding-top: 0px;
  padding-bottom: 5px;
  color: #909090;
}

.listActualite .containerActu .columnActu a .actue .contents {
  margin-bottom: 0;
  max-height: 86px;
  overflow: hidden;
}

.listActualite .containerActu .columnActu a .actue .knowMore {
  text-decoration: underline;
  margin: 5px 0 0 0;
  color: #000;
}

/*
.listActualite {
    display: flex;
    flex-direction: column;
    padding: 60px 10% 40px 10%;

    h1.title {
        margin-left: 20px;
        text-transform: uppercase;
        font-size: 70px;
        font-weight: 900;
        line-height: 55px;
        max-width: 100%;
        //overflow: hidden;

        @media (max-width: 993px) {
            font-size: 52px;
        }

        @media (max-width: 520px) {
            font-size: 38px;
        }
    }

    @import "../layout/sortBy.scss";

    .containerList {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 30px auto 0 auto;
        align-items: flex-start;
        //justify-content: center;

        &:after {
            content: "";
            flex: auto;
            //margin-left: 20px;
        }

        .columnActu {
            display: flex;
            justify-content: center;

            @media (min-width: 576px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }

            @media (min-width: 769px) {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (min-width: 992px) {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
            }


            @media (min-width: 1440px) {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
            }
            
            a {
                text-decoration: none;
                display: flex;
                justify-content: center;
            
                .actualite {
                    flex-direction: column;
                    margin: 20px;
                    max-width: 320px;
                    display: flex;
                    text-decoration: none;
                    border-radius: 4px;
                    transition: .3s;
                    flex: 1;
                    width: 95%;
                    min-width: 270px;

                    @media (max-width: 576px) {
                        width: 100%;
                    }

                    .infoActu {
                        max-width: 320px;
                        // width: 95%;

                        // @media (max-width: 576px) {
                        //     width: 100%;
                        // }
                    }

                    .createdAt, .title, .contents, .knowMore{
                        align-self:flex-start;
                        //padding: 5px;
                        color: black;
                        font-size: 14px;
                    }

                    .title {
                        font-weight: 900;
                        margin-bottom: 0px;
                        margin-top: 15px;
                        font-size: 21px;
                        padding-bottom: 0px;
                        word-break: break-word;
                    }

                    .createdAt {
                        margin: 0;
                        padding-top:0px;
                        padding-bottom:5px;
                        color: #909090;
                    }

                    .contents {
                        margin-bottom: 0;
                        max-height: 86px;
                        overflow: hidden;
                    }
                    
                    .cover {
                        max-width: 320px;
                        height: 420px;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        background-position: center !important;
                        transition: .3s;
                        // width: 95%;

                        // @media (max-width: 576px) {
                        //     width: 100%;
                        // }

                        @media (max-width: 576px) {
                            width: 100%;
                        }

                        &:hover {
                            box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, .2);
                            transform: scale(1.02);
                        }
                    }

                    .knowMore {
                        text-decoration: underline;
                        margin: 5px 0 0 0;
                        color: #000;
                    }
                }
            }
        }
    }   
}
*/
.oneNews {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
}

.oneNews div.containerTitle {
  display: inline-flex;
  margin: 0 10% 20px 10%;
  align-items: center;
}

@media (max-width: 1025px) {
  .oneNews div.containerTitle {
    margin: 0 30px 20px 30px;
  }
}

.oneNews div.containerTitle a {
  text-decoration: none;
  width: 70px;
  max-height: 100px;
  align-self: flex-start;
}

.oneNews div.containerTitle a .chevronLeft {
  margin: 1% 10px 0 0;
  background-image: url("/images/return.png");
  width: 100%;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 920px) {
  .oneNews div.containerTitle a .chevronLeft {
    width: 50%;
    height: 50px;
  }
}

.oneNews div.containerTitle h1 {
  flex: 3;
  font-size: 70px;
  line-height: 74px;
  margin-right: 10px;
  padding-right: 10px;
  font-weight: 900;
  flex: 6;
  word-break: break-word;
}

@media (max-width: 1025px) {
  .oneNews div.containerTitle h1 {
    font-size: 32px;
    line-height: 38px;
  }
}

.oneNews div.containerTitle p {
  font-size: 18px;
  align-self: flex-start;
  margin-left: 10px;
  font-weight: 500;
  color: #909090;
  flex: 2;
  text-align: right;
  padding-left: 10px;
}

.oneNews .blockNews {
  margin: 0 10% 40px 10%;
  display: flex;
}

@media (max-width: 769px) {
  .oneNews .blockNews {
    margin: 10px 30px;
  }
}

@media (max-width: 520px) {
  .oneNews .blockNews {
    margin: 10px;
  }
}

.oneNews .blockNews .imgBlockNews {
  max-width: 565px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  min-height: 465px;
}

@media (max-width: 1025px) {
  .oneNews .blockNews .imgBlockNews {
    background-size: cover;
    align-self: center;
    margin-bottom: 10px;
    max-width: 100%;
  }
}

.oneNews .blockNews .article {
  margin-left: 20px;
  width: 65%;
}

@media (max-width: 1025px) {
  .oneNews .blockNews .article {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 769px) {
  .oneNews .blockNews .article {
    margin-left: 0;
  }
}

.oneNews .blockNews .article article.newsText {
  margin: 0 0 0 40px;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 1025px) {
  .oneNews .blockNews .article article.newsText {
    margin-left: 0;
  }
}

@media (max-width: 769px) {
  .oneNews .blockNews .article article.newsText {
    margin: 0 10px;
  }
}

.oneNews .blockNews .article article.newsText p {
  margin: 0px;
  padding: 0px;
}

.oneNews .blockNews .article a, .oneNews .blockNews .article .subGame {
  text-decoration: none;
  display: block;
  margin: 20px auto;
}

.oneNews .blockNews .article a .btnSkew, .oneNews .blockNews .article .subGame .btnSkew {
  width: 230px;
  margin: 0 auto;
}

.oneNews .blockNews .article a .btnSkew div, .oneNews .blockNews .article .subGame .btnSkew div {
  height: 45px;
}

.oneNews .blockNews .article article p a {
  display: inline;
}

.oneNews .blockNews .article .containerForm {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.oneNews .blockNews .article .containerForm form {
  display: block;
  margin: 20px 40px 20px 0;
}

@media (max-width: 520px) {
  .oneNews .blockNews .article .containerForm form {
    margin: 0 5%;
  }
}

.oneNews .blockNews .article .containerForm form label {
  display: flex;
  width: 35%;
  float: left;
  justify-content: flex-end;
  margin-bottom: 20.5px;
  height: 40px;
  align-items: center;
  text-align: right;
}

@media (max-width: 769px) {
  .oneNews .blockNews .article .containerForm form label {
    display: none;
  }
}

.oneNews .blockNews .article .containerForm form input, .oneNews .blockNews .article .containerForm form textarea {
  padding: 0;
  display: flex;
  width: 61%;
  float: right;
  resize: vertical;
}

.oneNews .blockNews .article .containerForm form input::placeholder, .oneNews .blockNews .article .containerForm form textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cecece;
  opacity: 1;
  /* Firefox */
}

.oneNews .blockNews .article .containerForm form input:-ms-input-placeholder, .oneNews .blockNews .article .containerForm form textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cecece;
}

.oneNews .blockNews .article .containerForm form input::-ms-input-placeholder, .oneNews .blockNews .article .containerForm form textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cecece;
}

@media (max-width: 769px) {
  .oneNews .blockNews .article .containerForm form input, .oneNews .blockNews .article .containerForm form textarea {
    width: 95%;
    margin: 20px 0;
  }
}

.oneNews .blockNews .article .containerForm form input {
  border: none;
  border-bottom: 1px solid #cecece;
  margin-bottom: 20px;
  height: 40px;
}

.oneNews .blockNews .article .containerForm form textarea {
  border: 1px solid #cecece;
  margin-bottom: 20px;
  padding: 5px;
  width: 59%;
}

@media (max-width: 769px) {
  .oneNews .blockNews .article .containerForm form textarea {
    width: 95%;
    margin: 20px 0;
  }
}

.oneNews .blockNews .article .containerForm .btnSkew {
  display: flex;
  width: 45%;
  margin: 20px 20px 20px 39%;
}

@media (max-width: 769px) {
  .oneNews .blockNews .article .containerForm .btnSkew {
    width: 60%;
    margin: 20px auto;
  }
}

.oneNews .blockNews .article .containerForm .btnSkew div {
  flex: 1;
  height: 45px;
}

.oneNews .blockNews .article .containerForm .btnSend {
  visibility: hidden;
}

.oneNews .blockNews .article .containerForm .alert-success {
  color: #90C97E;
  transition: .3s;
}

@media (max-width: 1025px) {
  .oneNews .blockNews {
    flex-direction: column;
  }
}

.oneNews .blockNews .video {
  display: flex;
  justify-content: center;
}

.oneNews .blockNews .video iframe {
  min-width: 44vw !important;
}

.knowMore {
  display: flex;
  flex-direction: column;
}

.knowMore h3.title {
  text-align: center;
  font-size: 19px;
  font-weight: 900;
  margin-left: -9%;
}

@media (max-width: 426px) {
  .knowMore h3.title {
    margin-left: 0;
  }
}

.knowMore .containerKnowMore {
  display: flex;
  margin: 20px 10% 5% 10%;
  color: #000;
  justify-content: center;
}

.knowMore .containerKnowMore a {
  text-decoration: none;
  color: #000;
}

@media (max-width: 769px) {
  .knowMore .containerKnowMore {
    flex-direction: column;
    margin: 40px 30px;
  }
}

@media (max-width: 520px) {
  .knowMore .containerKnowMore {
    margin: 40px 10px;
  }
}

.knowMore .containerKnowMore .actualite {
  margin: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 300px;
}

@media (max-width: 769px) {
  .knowMore .containerKnowMore .actualite {
    max-width: 100%;
  }
}

.knowMore .containerKnowMore .actualite .cover {
  width: 100%;
  align-self: center;
  height: 400px;
  background-size: cover;
  background-position: center;
  max-width: 300px;
}

.knowMore .containerKnowMore .actualite .containerInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 10px;
  min-width: 100%;
}

.knowMore .containerKnowMore .actualite .containerInfo title {
  font-size: 17px;
  font-weight: 800;
  font-size: 21px;
}

@media (max-width: 993px) {
  .knowMore .containerKnowMore .actualite .containerInfo title {
    font-size: 17px;
  }
}

.knowMore .containerKnowMore .actualite .containerInfo h2 {
  margin-bottom: 5px;
  margin-top: 5px;
  word-break: break-word;
  font-size: 17px;
  font-weight: 800;
}

.knowMore .containerKnowMore .actualite .containerInfo .createdAt {
  color: #909090;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.knowMore .containerKnowMore .actualite .containerInfo .contents {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  max-height: 91px;
  overflow: hidden;
}

.knowMore .containerKnowMore .actualite .containerInfo .link {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.prodCard {
  display: flex;
  flex-direction: column;
}

.prodCard div.containerTitle {
  display: inline-flex;
  margin: 3% 10% 0 10%;
  align-items: center;
}

@media (max-width: 1025px) {
  .prodCard div.containerTitle {
    margin: 20px 30px;
  }
}

@media (max-width: 425px) {
  .prodCard div.containerTitle {
    margin: 20px 10px;
  }
}

.prodCard div.containerTitle a {
  text-decoration: none;
  width: 70px;
  max-height: 100px;
  align-self: flex-start;
}

.prodCard div.containerTitle a .chevronLeft {
  margin: 1% 10px 0 0;
  background-image: url("/images/return.png");
  width: 100%;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
}

.prodCard div.containerTitle h1 {
  flex: 3;
  font-size: 48px;
  line-height: 62px;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 800;
  word-break: break-word;
}

@media (max-width: 1025px) {
  .prodCard div.containerTitle h1 {
    font-size: 35px;
    line-height: 33px;
  }
}

@media (max-width: 425px) {
  .prodCard div.containerTitle h1 {
    font-size: 27px;
    line-height: 25px;
  }
}

.prodCard div.containerTitle p {
  font-size: 18px;
  align-self: flex-end;
  margin-left: 10px;
  font-weight: 500;
  color: #909090;
}

.prodCard .blockNews {
  margin: 3% 10% 40px 10%;
  display: flex;
}

@media (max-width: 769px) {
  .prodCard .blockNews {
    margin: 40px 30px;
  }
}

@media (max-width: 520px) {
  .prodCard .blockNews {
    margin: 20px 10px;
  }
}

.prodCard .blockNews .imgBlockNews {
  max-width: 525px;
  width: 100%;
  height: 745px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 1025px) {
  .prodCard .blockNews .imgBlockNews {
    align-self: center;
    margin-bottom: 10px;
    max-width: 100%;
  }
}

.prodCard .blockNews .article {
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  width: 55%;
}

@media (max-width: 1025px) {
  .prodCard .blockNews .article {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

.prodCard .blockNews .article h2 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 24px;
  line-height: 21px;
  text-transform: uppercase;
  font-weight: 800;
}

.prodCard .blockNews .article .prodText, .prodCard .blockNews .article ul li, .prodCard .blockNews .article p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.prodCard .blockNews .article p {
  margin: 0px;
  padding: 0px;
}

.prodCard .blockNews .article p span {
  font-weight: 700;
}

.prodCard .blockNews .article a {
  text-decoration: none;
  display: block;
  margin: 20px auto;
  width: 320px;
}

.prodCard .blockNews .article a .btnSkew {
  width: 300px;
  align-self: center;
}

.prodCard .blockNews .article article p a {
  display: inline;
}

@media (max-width: 1025px) {
  .prodCard .blockNews {
    flex-direction: column;
  }
}

.prodCard .cmNews .containerImgTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.prodCard .cmNews .containerImgTitle .imgBlockNews {
  max-width: 450px;
  height: 400px;
}

.prodCard .cmNews .containerImgTitle h1 {
  text-align: center;
}

.sectionVideo {
  margin: 20px 10% 40px 10%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 526px) {
  .sectionVideo {
    margin: 20px 0% 40px 0%;
  }
}

.sectionVideo .videoNormal {
  display: flex;
  order: 1;
  flex-wrap: wrap;
}

@media (max-width: 1025px) {
  .sectionVideo .videoNormal {
    flex-direction: column;
  }
}

.sectionVideo .videoNormal .oneMedia {
  width: 100%;
  flex: none !important;
  background-color: black;
}

.sectionVideo .videoNormal .oneMedia .imgVideo {
  height: 450px !important;
}

.sectionVideo .videoNormal .videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33%;
  margin: 10px 0;
}

.sectionVideo .videoNormal .videoContainer img:first-child {
  max-width: 95%;
  height: 300px;
}

@media (max-width: 1700px) {
  .sectionVideo .videoNormal .videoContainer img:first-child {
    height: auto;
  }
}

@media (max-width: 1025px) {
  .sectionVideo .videoNormal .videoContainer img:first-child {
    max-width: 400px;
    margin: 30px 0;
  }
}

@media (max-width: 526px) {
  .sectionVideo .videoNormal .videoContainer img:first-child {
    max-width: 320px;
    height: unset;
  }
}

.sectionVideo .videoNormal .videoContainer img:last-child {
  position: absolute;
  max-width: 4%;
}

.sectionVideo .videoNormal p {
  margin: 10px 0;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 769px) {
  .sectionVideo .videoNormal p {
    margin: 20px 60px;
  }
}

@media (max-width: 526px) {
  .sectionVideo .videoNormal p {
    margin: 20px 40px;
  }
}

.sectionVideo .autoplayVideoProd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sectionVideo .autoplayVideoProd i {
  font-size: 30px;
  margin: 10px;
}

.sectionVideo .autoplayVideoProd .videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}

.sectionVideo .autoplayVideoProd .videoContainer .imgVideo {
  max-width: 340px;
}

@media (max-width: 526px) {
  .sectionVideo .autoplayVideoProd .videoContainer .imgVideo {
    max-width: 310px;
  }
}

.sectionVideo .autoplayVideoProd .videoContainer .inputModalPlayer {
  position: absolute;
}

.sectionVideo .BA {
  order: 0;
}

#videoPlayer .modal {
  background: none;
  max-width: 1200px;
  margin-top: 7.5%;
}

#videoPlayer .modal .closeArrowVideosPop {
  right: 3%;
  position: absolute;
  top: 5%;
  color: #fff;
  font-size: 25px;
}

.sectionAwards {
  display: flex;
  flex-direction: column;
  margin: 20px 10% 40px 10%;
  background: #fff;
}

@media (max-width: 769px) {
  .sectionAwards {
    margin: 20px 60px;
  }
}

@media (max-width: 476px) {
  .sectionAwards {
    margin: 20px 30px;
  }
}

.sectionAwards .titleProd {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 800;
}

.sectionAwards div {
  display: flex;
  flex-wrap: wrap;
}

.sectionAwards div span {
  font-weight: 600;
}

.sectionAwards div article {
  flex: 0 0 30%;
  margin: 1.5%;
  padding: 0px;
}

.sectionAwards div article p {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 769px) {
  .sectionAwards div article {
    flex: 0 0 100%;
  }
}

.sectionPress {
  display: flex;
  flex-direction: column;
  padding: 20px 10% 40px 10%;
  background: #efefef;
}

@media (max-width: 769px) {
  .sectionPress {
    padding: 20px 60px;
  }
}

@media (max-width: 476px) {
  .sectionPress {
    margin: 20px 30px;
  }
}

.sectionPress .titleProd {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 800;
}

.sectionPress .articlePress {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sectionPress .articlePress p {
  margin: 20px 10px;
  width: 31%;
}

@media (max-width: 1072px) {
  .sectionPress .articlePress p {
    width: 47%;
  }
}

@media (max-width: 833px) {
  .sectionPress .articlePress p {
    width: 100%;
  }
}

.sectionDocuments {
  display: flex;
  flex-direction: column;
  margin: 20px 10% 40px 10%;
  background: #fff;
}

@media (max-width: 769px) {
  .sectionDocuments {
    margin: 20px 60px;
  }
}

@media (max-width: 476px) {
  .sectionDocuments {
    margin: 20px 30px;
  }
}

.sectionDocuments .titleProd {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 800;
}

.sectionDocuments .containerDocuments {
  display: flex;
  flex-direction: column;
}

.sectionDocuments .containerDocuments .document {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cecece;
}

.sectionDocuments .containerDocuments .document p {
  flex: 1;
}

.sectionDocuments .containerDocuments .document .iconsActions {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

.sectionDocuments .containerDocuments .document .iconsActions img {
  margin: 0 20px;
}

.sectionDocuments .containerDocuments .document .iconsActions img:first-child {
  height: 21px;
}

.sectionDocuments .containerDocuments .document .iconsActions img:last-child {
  height: 27px;
}

.sectionPhotos {
  display: flex;
  flex-direction: column;
  margin: 20px 10% 40px 10%;
  background: #fff;
}

@media (max-width: 769px) {
  .sectionPhotos {
    margin: 20px 60px;
  }
}

@media (max-width: 476px) {
  .sectionPhotos {
    margin: 20px 30px;
  }
}

.sectionPhotos .titleProd {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 800;
}

.sectionPhotos .autoplayPhotos {
  display: flex;
  align-items: center;
  margin: 20px auto 10px auto;
  width: 90%;
}

.sectionPhotos .autoplayPhotos .slick-track {
  display: flex;
}

.sectionPhotos .autoplayPhotos i.fas {
  font-size: 35px;
  margin: 10px;
}

.sectionPhotos .autoplayPhotos .slickImg {
  outline: none;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  flex-direction: column;
}

.sectionPhotos .autoplayPhotos .slickImg img {
  display: flex;
  margin: 10px;
  max-height: 400px;
}

@media (max-width: 1025px) {
  .sectionPhotos .autoplayPhotos .slickImg img {
    max-width: 300px;
  }
}

.sectionPhotos .autoplayPhotos .slickImg p {
  text-align: left;
  margin: 0px 10px 5px 10px;
  max-width: 265px;
}

.sectionPhotos .copyrightGlobal {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 30px;
}

#photosCarousel .modal {
  width: 100%;
  background: none;
  max-width: 95%;
  margin-top: 0%;
}

@media (max-width: 1442px) {
  #photosCarousel .modal {
    margin-top: 14%;
  }
}

#photosCarousel .closeArrowPhotosPop {
  right: 3%;
  position: absolute;
  top: 7%;
  color: #fff;
  font-size: 25px;
}

#photosCarousel .autoplayPhotosPop {
  display: flex;
  align-items: center;
}

#photosCarousel .autoplayPhotosPop i {
  font-size: 22px;
  color: #fff;
  align-self: center;
  margin: 0 8px;
  margin-top: 8%;
}

#photosCarousel .autoplayPhotosPop .slick-track {
  display: flex;
}

#photosCarousel .autoplayPhotosPop .slick-list {
  width: 100%;
}

#photosCarousel .autoplayPhotosPop .slick-list .slickImg {
  outline: none;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  height: 100vh;
}

#photosCarousel .autoplayPhotosPop .slick-list .slickImg img {
  display: flex;
  margin: 0 auto;
  max-width: 95%;
  max-height: 900px;
}

@media (max-width: 1441px) {
  #photosCarousel .autoplayPhotosPop .slick-list .slickImg img {
    max-height: 500px;
  }
}

#photosCarousel .autoplayPhotosPop .slick-list .slickImg p {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
}

.slick-prev:before {
  color: transparent;
}

#slick_partner .slick-next:before {
  color: transparent;
}

.slick-prev {
  background-image: url("/images/prev.png");
  background-repeat: no-repeat;
  opacity: 1;
  background-size: 60% 100%;
}

.slick-next {
  background-image: url("/images/next.png");
  background-repeat: no-repeat;
  opacity: 1;
  background-size: 60% 100%;
}

.slick-prev,
.slick-next {
  width: 80px;
  height: 45px;
}

.slick-prev {
  left: -140px;
}

.slick-next {
  right: -140px;
}

.cmCard  {
  color: blue;
}

.cmCard  .blockNews .imgBlockNews {
  max-width: 450px !important;
  height: 250px !important;
}

.nosProductions {
  display: flex;
  flex-direction: column;
}

.nosProductions div.sortBy {
  align-self: flex-end;
}

.nosProductions div.sortBy .sortByContainer {
  margin-right: 20px;
}

.nosProductions div.sortBy .sortByContainer span {
  margin: 0 10px;
  font-size: 15px;
  text-transform: uppercase;
}

.nosProductions div.sortBy .sortBySelection {
  display: none;
  width: 100px;
  height: 80px;
  background: #fff;
  position: absolute;
}

.nosProductions div.sortBy .sortBySelection ul {
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  list-style: none;
  text-transform: uppercase;
  font-size: 16px;
}

.nosProductions div.sortBy .sortBySelection ul li {
  align-self: center;
}

.nosProductions div.sortBy .sortBySelection ul li a {
  text-decoration: none;
  color: #000;
}

.nosProductions div.sortBy .showSortBy {
  display: flex;
  z-index: 1;
}

.nosProductions .filterProd {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #000;
}

.nosProductions .filterProd ul {
  padding: 0;
  margin: 20px;
  display: flex;
  list-style: none;
  flex: 1;
  justify-content: space-evenly;
}

@media (max-width: 920px) {
  .nosProductions .filterProd ul {
    display: none;
  }
}

.nosProductions .filterProd ul li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #fff;
  margin: 0;
  text-decoration: none;
}

.nosProductions .filterProd ul li:hover {
  color: #ff0000;
}

.nosProductions .filterProd ul li:first-child {
  color: #ff0000;
}

.nosProductions .filterProdMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  background: black;
  color: #fff;
}

.nosProductions .filterProdMobile .inputDisplayFilterProdMobile {
  color: #ff0000;
}

@media (min-width: 920px) {
  .nosProductions .filterProdMobile {
    display: none;
  }
}

.nosProductions .filterProdMobile i.fas {
  margin-left: 10px;
}

.nosProductions .filterProdMobile ul.listFilterProdMobile {
  display: none;
  margin: 0;
  position: absolute;
  list-style: none;
  width: 97%;
  top: 80px;
  flex-direction: column;
  background: #000;
  padding: 10px;
  align-items: center;
  z-index: 2;
}

.nosProductions .filterProdMobile ul.listFilterProdMobile li {
  display: flex;
  height: 70px;
  width: 100%;
  border-bottom: 1px solid #fff;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  margin: 0;
}

.nosProductions .filterProdMobile ul.listFilterProdMobile li:last-child {
  border: none;
}

.nosProductions .filterProdMobile .showListFilterProd {
  display: flex !important;
}

.nosProductions h1.title {
  color: #000000;
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  line-height: 58px;
  font-weight: 900;
  display: flex;
  flex: 1;
  text-transform: uppercase;
  margin: 50px 5% 10px 5%;
  padding-left: 20%;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 920px) {
  .nosProductions h1.title {
    font-size: 50px;
    line-height: 47px;
    padding-left: 0;
  }
}

@media (max-width: 520px) {
  .nosProductions h1.title {
    font-size: 38px;
    line-height: 36px;
  }
}

.nosProductions .containerCont {
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin: 20px 50px 30px 50px;
}

@media (max-width: 920px) {
  .nosProductions .containerCont {
    flex-direction: column;
    margin: 20px 20px 30px 20px;
  }
}

.nosProductions .containerCont .contents {
  display: flex;
  flex: 1;
  align-self: center;
  margin: 0 2% 0 5%;
  padding: 0 15%;
}

.nosProductions .containerCont .sortBy {
  margin-right: 5%;
  align-self: center;
}

@media (max-width: 920px) {
  .nosProductions .containerCont .sortBy {
    align-self: flex-end;
  }
}

.nosProductions .containerProduction {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

@media (max-width: 1560px) {
  .nosProductions .containerProduction {
    max-width: 90%;
    width: 90%;
  }
}

@media (max-width: 1080px) {
  .nosProductions .containerProduction {
    max-width: 100%;
    width: 100%;
  }
}

.nosProductions .containerProduction:after {
  content: "";
  width: 320px;
}

.nosProductions .containerProduction .columnProduction {
  width: 100%;
  position: relative;
}

@media (min-width: 576px) {
  .nosProductions .containerProduction .columnProduction {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .nosProductions .containerProduction .columnProduction {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .nosProductions .containerProduction .columnProduction {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
}

@media (min-width: 1440px) {
  .nosProductions .containerProduction .columnProduction {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.nosProductions .containerProduction .columnProduction a {
  max-width: 320px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}

.nosProductions .containerProduction .columnProduction a .production {
  margin-bottom: 30px;
  width: 95%;
  min-width: 260px;
}

@media (max-width: 576px) {
  .nosProductions .containerProduction .columnProduction a .production {
    width: 100%;
  }
}

.nosProductions .containerProduction .columnProduction a .production div.containerBackgroundImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  padding-bottom: 5px;
  height: 430px;
  max-height: 430px;
  background-position: center;
  background-size: cover;
  transition: .3s;
}

.nosProductions .containerProduction .columnProduction a .production div.containerBackgroundImg:hover {
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.nosProductions .containerProduction .columnProduction a .production .title {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  margin: 18px 20px 0 20px;
}

.nosProductions .containerProduction .columnProduction a .production .createdAt {
  font-size: 14px;
  color: #909090;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: center;
  line-height: 21px;
  margin-top: 5px;
}

.prodCardCm {
  display: flex;
  flex-direction: column;
}

.prodCardCm div.containerTitle {
  display: inline-flex;
  margin: 3% 10% 0 10%;
  align-items: center;
}

@media (max-width: 1025px) {
  .prodCardCm div.containerTitle {
    margin: 20px 30px;
  }
}

@media (max-width: 425px) {
  .prodCardCm div.containerTitle {
    margin: 20px 10px;
  }
}

.prodCardCm div.containerTitle a {
  text-decoration: none;
  width: 70px;
  max-height: 100px;
  align-self: flex-start;
}

.prodCardCm div.containerTitle a .chevronLeft {
  margin: 1% 10px 0 0;
  background-image: url("/images/return.png");
  width: 100%;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
}

.prodCardCm div.containerTitle h1 {
  flex: 3;
  font-size: 48px;
  line-height: 62px;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 800;
  word-break: break-word;
}

@media (max-width: 1025px) {
  .prodCardCm div.containerTitle h1 {
    font-size: 35px;
    line-height: 33px;
  }
}

@media (max-width: 425px) {
  .prodCardCm div.containerTitle h1 {
    font-size: 27px;
    line-height: 25px;
  }
}

.prodCardCm div.containerTitle p {
  font-size: 18px;
  align-self: flex-end;
  margin-left: 10px;
  font-weight: 500;
  color: #909090;
}

.prodCardCm .blockNews {
  margin: 3% 10% 40px 10%;
  display: flex;
}

@media (max-width: 769px) {
  .prodCardCm .blockNews {
    margin: 40px 30px;
  }
}

@media (max-width: 520px) {
  .prodCardCm .blockNews {
    margin: 20px 10px;
  }
}

.prodCardCm .blockNews .imgBlockNews {
  max-width: 525px;
  width: 100%;
  height: 715px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 1025px) {
  .prodCardCm .blockNews .imgBlockNews {
    align-self: center;
    margin-bottom: 10px;
    max-width: 100%;
  }
}

.prodCardCm .blockNews .article {
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  width: 55%;
}

@media (max-width: 1025px) {
  .prodCardCm .blockNews .article {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

.prodCardCm .blockNews .article h2 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 24px;
  line-height: 21px;
  text-transform: uppercase;
  margin-top: 10px;
  font-weight: 800;
}

.prodCardCm .blockNews .article .prodText, .prodCardCm .blockNews .article ul li, .prodCardCm .blockNews .article p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.prodCardCm .blockNews .article p {
  margin: 0px;
  padding: 0px;
}

.prodCardCm .blockNews .article p span {
  font-weight: 700;
}

.prodCardCm .blockNews .article a {
  text-decoration: none;
  display: block;
  margin: 20px auto;
  width: 320px;
}

.prodCardCm .blockNews .article a .btnSkew {
  width: 300px;
  align-self: center;
}

.prodCardCm .blockNews .article article p a {
  display: inline;
}

@media (max-width: 1025px) {
  .prodCardCm .blockNews {
    flex-direction: column;
  }
}

.prodCardCm .cmNews .containerImgTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.prodCardCm .cmNews .containerImgTitle h1 {
  text-align: center;
  margin-bottom: 10px;
}

.teamPage h2 {
  font-size: 40px;
  font-weight: 800;
  margin: 15px 20px 20px 20px;
}

@media (max-width: 769px) {
  .teamPage h2 {
    font-size: 35px;
  }
}

.teamPage .containerImg {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 55px;
}

.teamPage .containerImg .menuTeam {
  margin: 0;
  color: #fff;
  position: fixed;
  background: #000;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 55px;
  z-index: 1;
}

@media (max-width: 820px) {
  .teamPage .containerImg .menuTeam {
    display: none;
  }
}

.teamPage .containerImg .menuTeam span:first-child {
  color: #ff0000;
}

.teamPage .containerImg .menuTeam span {
  margin: 0 3%;
  font-size: 18px;
  font-weight: 900;
}

.teamPage .containerImg .menuTeam span:hover {
  color: #ff0000;
}

.teamPage .containerImg .menuTeamMobileContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
  align-items: center;
  z-index: 1;
}

@media (min-width: 820px) {
  .teamPage .containerImg .menuTeamMobileContainer {
    display: none;
  }
}

.teamPage .containerImg .menuTeamMobileContainer .inputMenuTeam {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #fff;
  position: fixed;
  background: #000;
  width: 100%;
  text-align: center;
  height: 60px;
}

.teamPage .containerImg .menuTeamMobileContainer .inputMenuTeam p {
  color: red;
  margin: 10px;
  font-size: 21px;
  font-weight: 700;
}

.teamPage .containerImg .menuTeamMobileContainer .inputMenuTeam i.fas {
  margin: 0 5px;
  color: #fff;
  z-index: 11;
}

.teamPage .containerImg .menuTeamMobileContainer .menuTeamMobile {
  background: #000;
  display: flex;
  display: none;
  width: 98%;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 60px;
  padding: 10px;
  z-index: 10000;
}

.teamPage .containerImg .menuTeamMobileContainer .menuTeamMobile span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #fff;
  width: 100%;
  cursor: pointer !important;
}

.teamPage .containerImg .menuTeamMobileContainer .menuTeamMobile span:last-child {
  border: none;
}

.teamPage .containerImg .menuTeamMobileContainer .showMenuTeamMobile {
  display: flex;
}

.teamPage .containerImg img {
  margin-top: 60px;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 660px;
  min-height: 380px;
  z-index: -1;
}

.teamPage .containerImg .containerContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  color: #fff;
  left: 0;
  align-self: center;
  width: 60%;
  margin: 2% 5%;
  z-index: -1;
}

.teamPage .containerImg .containerContent h1 {
  font-size: 60px;
  line-height: 58px;
}

@media (max-width: 820px) {
  .teamPage .containerImg .containerContent h1 {
    font-size: 35px;
    line-height: 33px;
  }
}

.teamPage .containerImg .containerContent p {
  font-size: 14px;
  margin: 10px 40px 0 0;
}

@media (max-width: 820px) {
  .teamPage .containerImg .containerContent p {
    font-size: 12px;
  }
}

.teamPage .containerCarouselSkew {
  display: flex;
  flex: 1;
  margin: 5%;
  padding-top: 40px;
}

@media (max-width: 820px) {
  .teamPage .containerCarouselSkew {
    flex-direction: column;
  }
}

.teamPage .containerCarouselSkew .containerImgSkew {
  display: flex;
  overflow: hidden;
  align-items: center;
  outline: none;
  flex: 1;
}

.teamPage .containerCarouselSkew .containerImgSkew .imgSkew {
  display: flex;
  background-image: url("/images/img-home.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%, 40% 650px);
  flex: 1;
  align-self: center;
  height: 290px;
}

@media (max-width: 1025px) {
  .teamPage .containerCarouselSkew .containerImgSkew .imgSkew {
    clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%, 30% 400px);
  }
}

.teamPage .containerCarouselSkew .carousel {
  display: flex;
  flex: 1;
  max-width: 65%;
  min-width: 60%;
}

@media (max-width: 425px) {
  .teamPage .containerCarouselSkew .carousel {
    max-width: 100%;
  }
}

.teamPage .containerCarouselSkew .carousel .slick-list {
  width: 100%;
  flex: 1;
}

.teamPage .containerCarouselSkew h2 {
  margin: 15px 0 0 28px;
  line-height: 34px;
}

.teamPage .containerCarouselSkew p {
  flex: 1;
  margin: 0 30px 0 30px;
}

@media (max-width: 820px) {
  .teamPage .containerCarouselSkew p {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.teamPage .showTeamSection {
  display: flex !important;
}

.teamPage .containerTeam {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 40px;
}

.teamPage .containerTeam .firstText {
  font-size: 14px;
  font-weight: 500;
  width: 40%;
  margin: 0 auto 35px auto;
  text-align: center;
}

@media (max-width: 525px) {
  .teamPage .containerTeam .firstText {
    width: 80%;
  }
}

.teamPage .containerTeam .firstText p {
  margin-top: 0;
}

.teamPage .containerTeam .containerMember {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
}

@media (max-width: 769px) {
  .teamPage .containerTeam .containerMember {
    flex-direction: column;
    margin-left: -40px;
  }
}

.teamPage .containerTeam .containerImgSkew {
  display: flex;
  overflow: hidden;
  width: 400px;
  padding: 10px 50px;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1400px) {
  .teamPage .containerTeam .containerImgSkew {
    width: 22.5%;
  }
}

@media (max-width: 769px) {
  .teamPage .containerTeam .containerImgSkew {
    flex: inherit;
    padding: 0 10px;
    margin-left: 60px;
    width: 400px;
  }
}

@media (max-width: 376px) {
  .teamPage .containerTeam .containerImgSkew {
    flex: inherit;
    padding: 0px;
    max-width: 320px;
  }
}

.teamPage .containerTeam .containerImgSkew .imgSkewParent {
  width: 215px;
  overflow: hidden;
  margin-bottom: 20px;
  transform: skew(20deg);
}

.teamPage .containerTeam .containerImgSkew .imgSkewParent .imgSkew {
  transform: skew(-20deg);
  display: flex;
  background-image: url("/images/img-home.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  align-self: center;
  width: 324px;
  height: 285px;
  left: -27%;
  position: relative;
}

.teamPage .containerTeam .containerImgSkew p {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  margin-left: 18%;
  font-size: 14px;
  color: #909090;
  font-weight: 500;
}

@media (max-width: 525px) {
  .teamPage .containerTeam .containerImgSkew p {
    margin-left: 2%;
    margin-right: 2%;
  }
}

.teamPage .containerTeam .containerImgSkew p span {
  font-weight: 900;
  font-size: 18px;
  color: #000;
  font-weight: 700;
}

.teamPage .containerReward {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 40px;
}

.teamPage .containerReward .firstText {
  font-size: 14px;
  font-weight: 500;
  width: 40%;
  margin: 0 auto 35px auto;
  text-align: center;
}

@media (max-width: 525px) {
  .teamPage .containerReward .firstText {
    width: 80%;
  }
}

.teamPage .containerReward .firstText p {
  margin-top: 0;
}

.teamPage .containerReward .containerAward {
  display: flex;
  margin: 30px 10%;
  min-width: 80%;
}

@media (max-width: 769px) {
  .teamPage .containerReward .containerAward {
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
}

.teamPage .containerReward .containerAward img {
  height: 100px;
  width: 100px;
  align-self: center;
}

.teamPage .containerReward .containerAward p:nth-child(1) {
  font-weight: 300;
  color: #909090;
  margin: 0;
}

.teamPage .containerReward .containerAward p:nth-child(2) {
  font-weight: 700;
  margin: 0;
}

.teamPage .containerReward .containerAward p:nth-child(3) {
  margin: 10px 0 0 0;
}

.teamPage .containerReward .containerAward .asideText {
  margin-left: 100px;
}

@media (max-width: 769px) {
  .teamPage .containerReward .containerAward .asideText {
    margin: 20px 10px 0 10px;
  }
}

.teamPage .containerReward .containerAward .asideText h3 {
  margin-bottom: 0;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
}

.teamPage .containerReward .containerAward .asideText p {
  margin-bottom: 10px;
  font-weight: 500;
  color: #000;
}

.teamPage .containerReward .containerAward .asideText article {
  font-size: 14px;
  font-weight: 500;
}

.teamPage .containerReward .containerAward .asideText article p {
  color: #000;
}

.teamPage .containerCoDir {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.teamPage .containerCoDir .firstText {
  font-size: 14px;
  font-weight: 500;
  width: 40%;
  margin: 0 auto 35px auto;
  text-align: center;
}

@media (max-width: 525px) {
  .teamPage .containerCoDir .firstText {
    width: 80%;
  }
}

.teamPage .containerCoDir .firstText p {
  margin-top: 0;
}

.teamPage .containerCoDir .containerMember {
  z-index: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  z-index: 0;
}

@media (max-width: 769px) {
  .teamPage .containerCoDir .containerMember {
    flex-direction: column;
  }
}

.teamPage .containerCoDir .containerImgSkew {
  display: flex;
  width: 360px;
  padding: 10px 50px;
  z-index: -1;
  flex-direction: column;
  align-items: center;
}

.teamPage .containerCoDir .containerImgSkew:after {
  content: "";
  flex: auto;
}

@media (max-width: 1400px) {
  .teamPage .containerCoDir .containerImgSkew {
    width: 22.5%;
  }
}

@media (max-width: 769px) {
  .teamPage .containerCoDir .containerImgSkew {
    width: 100%;
    flex: inherit;
    padding: 0 10px;
  }
}

@media (max-width: 376px) {
  .teamPage .containerCoDir .containerImgSkew {
    flex: inherit;
    padding: 0px;
    max-width: 320px;
  }
}

.teamPage .containerCoDir .containerImgSkew .parentImgSkew {
  width: 215px;
  overflow: hidden;
  margin-bottom: 20px;
  transform: skew(20deg);
  transition: .3s;
}

@media (max-width: 769px) {
  .teamPage .containerCoDir .containerImgSkew .parentImgSkew {
    width: 60%;
  }
}

@media (max-width: 376px) {
  .teamPage .containerCoDir .containerImgSkew .parentImgSkew {
    width: 190px;
  }
}

.teamPage .containerCoDir .containerImgSkew .parentImgSkew:hover {
  box-shadow: 3px 2px 8px 3px rgba(0, 0, 0, 0.4);
}

.teamPage .containerCoDir .containerImgSkew .parentImgSkew:hover .imgSkew {
  transform: scale(1.1) skew(-20deg);
}

.teamPage .containerCoDir .containerImgSkew .parentImgSkew .imgSkew {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  align-self: center;
  left: -27%;
  width: 324px;
  height: 285px;
  position: relative;
  transform: skew(-20deg);
  transition: .3s;
}

@media (max-width: 769px) {
  .teamPage .containerCoDir .containerImgSkew .parentImgSkew .imgSkew {
    width: 320px;
  }
}

@media (max-width: 376px) {
  .teamPage .containerCoDir .containerImgSkew .parentImgSkew .imgSkew {
    width: 155%;
  }
}

.teamPage .containerCoDir .containerImgSkew p {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  margin-left: 18%;
  font-size: 14px;
  color: #909090;
  font-weight: 500;
}

.teamPage .containerCoDir .containerImgSkew p span {
  font-weight: 900;
  font-size: 18px;
  color: #000;
  font-weight: 700;
}

.teamPage .containerCoDir .containerInterview {
  z-index: 1;
  display: flex;
  padding: 10px 0;
  background-color: #efefef;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none !important;
}

.teamPage .containerCoDir .containerInterview .autoplayInterview {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teamPage .containerCoDir .containerInterview .autoplayInterview .slick-list {
  width: 90% !important;
}

.teamPage .containerCoDir .containerInterview .containerContInter {
  display: flex;
  margin: 0 120px;
}

@media (max-width: 1025px) {
  .teamPage .containerCoDir .containerInterview .containerContInter {
    flex-direction: column;
    margin: 0 20px;
  }
}

.teamPage .containerCoDir .containerInterview .containerContInter .containerImgSkew2 {
  display: flex;
  overflow: hidden;
  width: 340px;
  padding: 10px;
  z-index: 0;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1025px) {
  .teamPage .containerCoDir .containerInterview .containerContInter .containerImgSkew2 {
    width: 95%;
    flex: inherit;
  }
}

.teamPage .containerCoDir .containerInterview .containerContInter .containerImgSkew2 .imgSkew {
  display: flex;
  background-image: url("/images/img-home.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  clip-path: polygon(0px 30px, 270px 30px, 340px 260px, 100px 260px, 70px 260px);
  align-self: center;
  width: 340px;
  height: 285px;
}

.teamPage .containerCoDir .containerInterview .containerContInter .containerImgSkew2 p {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

.teamPage .containerCoDir .containerInterview .containerContInter .containerImgSkew2 p span {
  font-weight: 900;
}

.teamPage .containerCoDir .containerInterview .containerContInter .containerQuestionAnswer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
  margin-left: 30px;
}

@media (max-width: 1025px) {
  .teamPage .containerCoDir .containerInterview .containerContInter .containerQuestionAnswer {
    margin-left: 10px;
    width: 95%;
    flex: inherit;
  }
}

.teamPage .containerCoDir .containerInterview .containerContInter .containerQuestionAnswer article {
  font-weight: 500;
  margin-bottom: 0;
}

.teamPage .containerCoDir .containerInterview .containerContInter .containerQuestionAnswer article p {
  margin: 0px;
  padding: 0px;
}

.teamPage .containerCoDir .containerInterview h3 {
  text-align: left;
  font-size: 21px;
}

.teamPage .containerModal {
  top: 20px;
}

.teamPage .modal .containerInterview {
  display: block !important;
}

.teamPage .modal p {
  overflow: initial;
}

.teamPageCase .containerClassic .teamPage .containerSpePropos {
  display: none;
}

.teamPageCase .containerClassic:nth-child(2) .containerSpePropos {
  display: flex;
}

.contactPage {
  display: flex;
  flex-direction: column;
}

@media (max-width: 920px) {
  .contactPage {
    flex-direction: column;
  }
}

.contactPage .containerContactInfo {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 60%;
  background: #000;
  color: #fff;
  clip-path: polygon(50% 0%, 80% 0%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
  z-index: 1;
  height: calc(100vh - 75px);
  max-height: 700px;
}

@media (max-width: 921px) {
  .contactPage .containerContactInfo {
    width: 100%;
    clip-path: none;
    position: unset;
    max-height: 540px;
  }
}

.contactPage .containerContactInfo .text {
  display: flex;
  flex-direction: column;
  margin: 60px 120px 120px 12.5%;
}

@media (max-width: 921px) {
  .contactPage .containerContactInfo .text {
    margin: 20px 20px 60px 20px;
  }
}

.contactPage .containerContactInfo .text article {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
}

@media (max-width: 1441px) {
  .contactPage .containerContactInfo .text article {
    font-size: 12px;
    font-weight: 300;
  }
}

.contactPage .containerContactInfo .text h1 {
  text-transform: uppercase;
  font-size: 55px;
  font-weight: 900;
  margin-bottom: 30px;
}

@media (max-width: 921px) {
  .contactPage .containerContactInfo .text h1 {
    font-size: 45px;
  }
}

.contactPage .containerContactInfo .text h2 {
  margin-bottom: 0;
  margin-top: 30px;
  font-weight: 500;
}

@media (max-width: 921px) {
  .contactPage .containerContactInfo .text h2 {
    margin-top: 40px;
  }
}

.contactPage .containerContactInfo .text p.makingProd {
  font-weight: 100;
  font-size: 30px;
  margin-bottom: 0;
}

.contactPage .containerContactInfo .text p.makingProd span {
  font-weight: 700;
}

.contactPage .containerContactInfo .text .containerImg {
  max-width: 100px;
}

.contactPage .containerContactInfo .text .containerImg img {
  max-height: 80px;
}

.contactPage .containerMainInfo {
  height: calc(100vh - 75px);
  max-height: 700px;
}

@media (max-width: 921px) {
  .contactPage .containerMainInfo {
    max-height: 840px;
    height: 840px;
  }
}

.contactPage .map {
  height: 100%;
  display: flex;
  width: 55%;
  margin-left: 45%;
}

@media (max-width: 921px) {
  .contactPage .map {
    width: 100%;
    margin-left: 0;
    clip-path: none;
    position: unset;
    max-height: 300px;
    height: 300px;
  }
}

.contactPage .containerInfoSup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 60px 7.5%;
}

@media (max-width: 921px) {
  .contactPage .containerInfoSup {
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
    padding-bottom: 20px;
  }
}

.contactPage .containerInfoSup .info {
  width: 50%;
}

.contactPage .containerInfoSup .info h2 {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 900;
}

.contactPage .containerInfoSup .info p {
  margin: 0;
  font-weight: 700;
}

.contactPage .containerInfoSup .info:last-child {
  margin-left: 70px;
}

@media (max-width: 921px) {
  .contactPage .containerInfoSup .info {
    margin-left: 70px;
  }
}

.legalMentionPage {
  display: flex;
  flex-direction: column;
}

.legalMentionPage .containerMentions {
  margin: 60px 120px;
}

@media (max-width: 769px) {
  .legalMentionPage .containerMentions {
    margin: 20px 60px;
  }
}

.legalMentionPage .containerMentions h2 {
  text-transform: uppercase;
  font-size: 16px;
}

.legalMentionPage .containerMentions p {
  margin-bottom: 20px;
}
