
//ALL PROD
.nosProductions {
    display: flex;
    flex-direction: column;

    @import "../layout/sortBy.scss";

    .filterProd {
        display: flex;
        flex-direction: row;
        flex: 1;
        background: #000;

        ul {
            padding: 0;
            margin: 20px;
            display: flex;
            list-style: none;
            flex: 1;
            justify-content: space-evenly;

            @media (max-width: 920px) {
                display: none;
            }

            li {
                list-style-type: none;
                display: inline-block;
                text-align: center;
                font-size: 18px;
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                color: #fff;
                margin: 0;
                text-decoration:none;

                &:hover {
                    color:#ff0000;
                }

                &:first-child {
                    color: #ff0000;
                }
            }
        }
    }

    .filterProdMobile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 15px;
        background: black;
        color: #fff;

        .inputDisplayFilterProdMobile {
            color: #ff0000;
        }

        @media (min-width: 920px) {
            display: none;
        }

        i.fas {
            margin-left: 10px;
        }

        ul.listFilterProdMobile {
            display: none;
            margin: 0;
            position: absolute;
            list-style: none;
            width: 97%;
            top: 80px;
            flex-direction: column;
            background: #000;
            padding: 10px;
            align-items: center;
            z-index: 2;

            li {
                display: flex;
                height: 70px;
                width: 100%;
                border-bottom: 1px solid #fff;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #fff;
                margin: 0;

                &:last-child {
                    border: none;
                }
            }
        }

        .showListFilterProd {
            display: flex!important;
        }
    }

    h1.title {
        color: #000000;
        font-family: 'Poppins', sans-serif;
        font-size: 48px;
        line-height: 58px;
        font-weight: 900;
        display: flex;
        flex: 1;
        text-transform: uppercase;
        margin: 50px 5% 10px 5%;
        padding-left: 20%;
        max-width: 100%;
        overflow: hidden;

        @media (max-width: 920px) {
            font-size: 50px;
            line-height: 47px;
            padding-left: 0;
        }

        @media (max-width: 520px) {
            font-size: 38px;
            line-height: 36px;
        }
    }

    .containerCont {
        display: flex;
        flex: 1;
        justify-content: space-around;
        margin: 20px 50px 30px 50px;

        @media (max-width: 920px) {
            flex-direction: column;
            margin: 20px 20px 30px 20px;
        }

        .contents {
            display: flex;
            flex: 1;
            align-self: center;
            margin: 0 2% 0 5%;
            padding: 0 15%;
        }

        .sortBy {
            margin-right: 5%;
            align-self: center;

            @media (max-width: 920px) {
                align-self: flex-end;
            }
        }
    }

    .containerProduction {
        display: flex;
        flex-wrap: wrap;
        max-width: 80%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;

        @media (max-width: 1560px) {
            max-width: 90%;
            width: 90%;
        }

        @media (max-width: 1080px) {
            max-width: 100%;
            width: 100%;
        }

        &:after {
            content: "";
            width: 320px;
        }

        .columnProduction {
            width: 100%;
            position: relative;
            
            @media (min-width: 576px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }

            @media (min-width: 769px) {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (min-width: 992px) {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
            }


            @media (min-width: 1440px) {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
            }


            a {
                max-width: 320px;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                text-decoration: none;

                .production {
                    margin-bottom: 30px;
                    width: 95%;
                    min-width: 260px;

                    @media (max-width: 576px) {
                        width: 100%;
                    }
                    
                    div.containerBackgroundImg {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 0px;
                        padding-bottom: 5px;
                        height: 430px;
                        max-height: 430px;
                        background-position: center;
                        background-size: cover;
                        transition: .3s;

                        &:hover {
                            box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, .2);
                            transform: scale(1.02);
                        }
                    }
                    
                    .title {
                        color: #000;
                        font-family: 'Poppins', sans-serif;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 25px;
                        text-align: center;
                        margin: 18px 20px 0 20px;
                    }
                    
                    .createdAt {
                        font-size: 14px;
                        color: #909090;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        text-align: center;
                        line-height: 21px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
